import { Policy } from "@mui/icons-material";
export type Imports = {
  style?: Partial<Style>;
  skipUse?: boolean;
  folder: string;
  componentName: string;
  alias?: string;
  props?: Component["variables"];
  id: string;
};

export type Variables = {
  characters: string;
  style: Partial<Style>;
  paths: Partial<Path>[];
  width: number | string;
  height: number | string;
  imports?: Imports[];
  scaleMode?: string;
  url?: string;
  additionalStyles?: Array<{ style: Partial<Style>; class: string }>;
  wrapper?: {
    openTag?: string;
    closeTag?: string;
  };
};

export type Style = {
  filter?: string;
} & React.CSSProperties;

export type Path = {
  path: string;
  fill: string;
  stroke?: string;
  strokeWidth: number;
  strokeLocation: string;
};

export type Component = {
  // this is layer name from figma | Frame 01 | Frame 02 | Frame 03 | Rectangle 04
  name: string;
  // this is component name from figma VECTORS | POLYGONS | RECTANGLES | TEXT | COMPONENTS
  figmaName: string;
  children: Component[];
  variables?: Partial<Variables>;
  id: string;
};

export type ChildrenCSS = {
  class: string;
  style: Partial<Style>;
};
export interface ProjectPageFields {
  frameId: string;
  location: string;
  name: string;
  needUpdate: boolean;
}

export type ProjectPages = Map<string, ProjectPageFields>;

export type Project = {
  projectId: string;
  name: string;
  description: string;
  thumbnail: string;
  timestamp: number;
  subdomain: {
    address: string;
    published: boolean;
  };
  customDomain: {
    address: string;
    published: boolean;
  };
  customCode?: string;
  customHead?: string;
  isSynced?: boolean;
  pages: ProjectPages;
  subscription: SubscriptionInfo;
  policy: Policy;
} & ActiveProject;

export type ProjectHistory = {
  config: Record<string, unknown>;
  metadata: Record<string, unknown>;
  scripts: string[];
};

export type ActiveProject = {
  active: boolean;
  html: string;
  css: string;
  variables: string;
  projectHead: string;
  projectBody: string;
  projectCloseTag: string;
  projectCSSResetLink: string;
  history: ProjectHistory[];
  colorReferencesToMix: string;
  pages: ProjectPages;
  jsScripts: string[];
};

export type Page = {
  frameId: string;
  name: string;
  component: string;
  styles: string;
  slug: string;
};

export type PublishPage = {
  frameId: string;
  name: string;
  component: string;
  styles: string;
};

export type PageScripts = {
  fileName: string;
  content: string;
};

export type ProjectPublish = {
  projectId: string;
  reset?: string;
  pages: PublishPage[];
  variables: string;
  customDomain?: string;
  component?: string;
  scripts: PageScripts[];
};

export enum SubscriptionStatus {
  active = "active",
  canceled = "canceled",
  trialing = "trialing",
  unpaid = "unpaid",
  past_due = "past_due",
  incomplete = "incomplete",
  incomplete_expired = "incomplete_expired",
  paused = "paused",
}

type TSubscriptionStatus = keyof typeof SubscriptionStatus;

export type SubscriptionInfo = {
  priceId: string;
  id: string;
  status: TSubscriptionStatus;
  currentPeriodStart: number;
  currentPeriodEnd: number;
  isClosed?: boolean;
  nextPlanPriceId?: string;
};

export type Policy = {
  maxPages: number;
  projectId: string;
  plan: string;
  canPublishOnCustomDomain: boolean;
  version: string;
};
