import { type CSSProperties } from "react";

type TDesignStyle = {
  className?: string;
  style?: CSSProperties;
};

export const DesignStyle = ({ className, style }: TDesignStyle) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    style={style}
  >
    <path d="M4.36134 13.0893L11.0894 6.36189L17.8124 13.0844L11.084 19.812L4.36134 13.0893Z" fill="#3F3F46" />
    <path
      d="M11.084 19.812L17.8124 13.0844C13.3306 13.0845 8.84314 13.0894 4.36134 13.0893L11.084 19.812Z"
      fill="#71717A"
    />
    <path
      d="M4.36134 13.0893L3.83104 12.559C3.69038 12.6996 3.61135 12.8904 3.61134 13.0893C3.61134 13.2882 3.69036 13.479 3.83101 13.6196L4.36134 13.0893ZM11.0894 6.36189L11.6198 5.83154L11.6197 5.83152L11.0894 6.36189ZM17.8124 13.0844L18.3427 13.6148C18.4834 13.4742 18.5624 13.2834 18.5624 13.0845C18.5624 12.8855 18.4834 12.6948 18.3427 12.5541L17.8124 13.0844ZM11.084 19.812L10.5537 20.3423C10.8466 20.6352 11.3214 20.6352 11.6143 20.3424L11.084 19.812ZM8.25778 2.4701C7.96486 2.17723 7.48999 2.17727 7.19712 2.47019C6.90425 2.76311 6.90429 3.23798 7.1972 3.53085L8.25778 2.4701ZM4.89165 13.6197L11.6198 6.89225L10.5591 5.83153L3.83104 12.559L4.89165 13.6197ZM10.5591 6.89224L17.2821 13.6148L18.3427 12.5541L11.6198 5.83154L10.5591 6.89224ZM17.2821 12.5541L10.5537 19.2816L11.6143 20.3424L18.3427 13.6148L17.2821 12.5541ZM11.6197 5.83152L8.25778 2.4701L7.1972 3.53085L10.5592 6.89226L11.6197 5.83152ZM11.6144 19.2817L4.89168 12.559L3.83101 13.6196L10.5537 20.3423L11.6144 19.2817ZM4.36162 13.8393L17.8127 13.8344L17.8121 12.3344L4.36107 12.3393L4.36162 13.8393ZM4.36133 13.8393C8.83502 13.8394 13.3398 13.8345 17.8124 13.8344L17.8124 12.3344C13.3214 12.3345 8.85126 12.3394 4.36136 12.3393L4.36133 13.8393Z"
      fill="#71717A"
    />
    <path
      d="M17.5696 20.3503C17.9492 20.7731 18.4173 20.9844 18.974 20.9844C19.5309 20.9844 20.0078 20.7731 20.4047 20.3503C20.8016 19.9274 21 19.4093 21 18.796C21 18.3413 20.8988 17.9074 20.6964 17.4944C20.4939 17.0816 20.2609 16.6853 19.9971 16.3054L18.974 14.9844L18.0031 16.3054C17.7287 16.6853 17.4929 17.0816 17.2958 17.4944C17.0986 17.9074 17 18.3413 17 18.796C17 19.4093 17.1899 19.9274 17.5696 20.3503Z"
      fill="#71717A"
    />
  </svg>
);
