import { type CSSProperties } from "react";

type TSizeMobile = {
  className?: string;
  style?: CSSProperties;
};

export const SizeMobile = ({ className, style }: TSizeMobile) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M9.83329 13.3333H11.1666M8.11901 4.16666H12.8809C13.4069 4.16666 13.8333 4.68899 13.8333 5.33332V14.6667C13.8333 15.311 13.4069 15.8333 12.8809 15.8333H8.11901C7.59302 15.8333 7.16663 15.311 7.16663 14.6667V5.33332C7.16663 4.68899 7.59302 4.16666 8.11901 4.16666Z"
      stroke="#71717A"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
