import { useState, type MouseEvent } from "react";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

import useOutsideClick from "../../../../hooks/useOutsideClick/useOutsideClick";
import { Lock } from "../../../../../assets/svg/lock";
import { Starter } from "../../../../assets/svg/Starter";
import { EssentialPlan } from "../../../../assets/svg/EssentialPlan";
import { ProPlan } from "../../../../assets/svg/ProPlan";

import { Modal } from "./components/Modal";
import { ProjectCardActions } from "./components/ProjectCardActions";

import type { TProjectCard } from "./ProjectCard.types";

import "./ProjectCard.css";

export const ProjectCard: TProjectCard = ({
  name,
  timestamp,
  thumbnail,
  onClick,
  handlePlanUpgrade,
  onEdit,
  onDelete,
  onDuplicate,
  isLocked = false,
  isLoading: isLoadingProp = false,
  isPublished = false,
  skeleton = false,
  style,
  plan,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(isLoadingProp);

  const menuRef = useOutsideClick<HTMLDivElement>(() => {
    setIsMenuOpen(false);
  });
  const modalRef = useOutsideClick<HTMLDivElement>(() => setIsDeleteModalOpen(false));

  const editedDate = dayjs(timestamp).from(dayjs());

  const handleOnClick = (e: MouseEvent<HTMLDivElement>) => {
    if (!isLocked) {
      e.stopPropagation();
      onClick && onClick();
    }
  };

  const handleOnClickKey = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // need here so confirm modal doesn't try open project
    if (isDeleteModalOpen || isDuplicateModalOpen) return;
    if (!isLocked && (event.key === "Enter" || event.key === " ")) {
      event.preventDefault();
      onClick && onClick();
    }
  };

  const handleOpenModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsDeleteModalOpen(true);
  };

  const handleOpenDuplicateModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsDuplicateModalOpen(true);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsMenuOpen((prev) => !prev);
  };

  const handleOpenMenuKey = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if ((!isLocked && event.key === "Enter") || event.key === " ") {
      event.preventDefault();
      setIsMenuOpen((prev) => !prev);
    }
  };

  const handleOnDelete = async () => {
    if (onDelete !== undefined) {
      setIsLoading(true);
      await onDelete();
      setIsLoading(false);
    }
  };

  const handleOnDuplicate = async () => {
    if (onDuplicate !== undefined) {
      setIsLoading(true);
      await onDuplicate();
      setIsLoading(false);
    }
  };

  const handleOnEdit = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onEdit && onEdit();
  };

  const handleOnPlanUpgrade = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    handlePlanUpgrade && handlePlanUpgrade();
  };

  const planeLabel = () => {
    switch (plan) {
      case "free":
        return (
          <>
            <Starter /> Starter Plan
          </>
        );
      case "essential":
        return (
          <>
            <EssentialPlan />
            Essential plan
          </>
        );
      case "pro":
        return (
          <>
            <ProPlan /> Pro plan
          </>
        );
      default:
        return "Starter";
    }
  };

  const statusLabel = () => {
    if (isLocked) {
      return <span className="project-card__status-locked">Locked</span>;
    }
    if (isPublished) {
      return <span className="project-card__status-published">Published</span>;
    }
    return <span className="project-card__status-unpublished">Unpublished</span>;
  };

  return (
    <>
      <div
        className={
          "project-card" + (isMenuOpen ? " project-card--menu-open" : "") + (isLocked ? " project-card--locked" : "")
        }
        tabIndex={0}
        role="button"
        onClick={handleOnClick}
        onKeyDown={handleOnClickKey}
        style={style}
      >
        {skeleton ? (
          <div className="project-card__thumbnail skeleton-thumbnail skeleton" />
        ) : (
          <div className="project-card__thumbnail">
            <img className="project-card__thumbnail-img" src={thumbnail} loading="lazy" alt="project thumbnail" />
            {isLocked && (
              <div className="project-card__locked-overlay">
                <div className="svg-wrapper">
                  <Lock />
                </div>
                <h3>Site locked</h3>
                <p>Pay for your site plan</p>
              </div>
            )}
          </div>
        )}
        <div className="project-card__info">
          {skeleton ? (
            <div className="project-card__typography">
              <div className="skeleton skeleton-text" />
              <div className="skeleton skeleton-text" />
            </div>
          ) : (
            <div className="project-card__typography">
              <div className="project-card__title">{name}</div>
              <div className="project-card__description">{`Edited ${editedDate}`}</div>
            </div>
          )}
          <ProjectCardActions
            isLoading={isLoading}
            isMenuOpen={isMenuOpen}
            isDeleteModalOpen={isDeleteModalOpen}
            isDuplicateModalOpen={isDuplicateModalOpen}
            plan={plan}
            handleOpenMenuKey={handleOpenMenuKey}
            handleOpenMenu={handleOpenMenu}
            handleOnPlanUpgrade={handleOnPlanUpgrade}
            handleOnEdit={handleOnEdit}
            handleOpenModal={handleOpenModal}
            handleOpenDuplicateModal={handleOpenDuplicateModal}
            ref={isMenuOpen ? menuRef : null}
          />
        </div>
        {skeleton ? null : (
          <div className="project-card__status">
            <button
              className={`project-card__project-plan project-card__project-plan--${plan}`}
              onClick={handlePlanUpgrade}
            >
              {planeLabel()}
            </button>
            <span className="project-card__status-text">{statusLabel()}</span>
          </div>
        )}
      </div>
      {isDeleteModalOpen && (
        <Modal
          title="Delete this project?"
          description="Are you sure you want to delete this project? This action cannot be undone."
          cancelButtonLabel="Cancel"
          confirmButtonLabel="Delete"
          onClose={() => setIsDeleteModalOpen(false)}
          onClick={handleOnDelete}
        />
      )}
      {isDuplicateModalOpen && (
        <Modal
          title="Duplicate this project?"
          description="Are you sure you want to duplicate this project?"
          cancelButtonLabel="Cancel"
          confirmButtonLabel="Duplicate"
          onClose={() => setIsDuplicateModalOpen(false)}
          onClick={handleOnDuplicate}
        />
      )}
    </>
  );
};
