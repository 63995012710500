import type { SVGProps } from "react";

export const Home = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="Icon/Navigation icon/Selected">
      <path
        id="Vector"
        d="M3 10.0005C2.99993 9.70952 3.06333 9.42208 3.18579 9.15817C3.30824 8.89427 3.4868 8.66025 3.709 8.47246L10.709 2.47346C11.07 2.16836 11.5274 2.00098 12 2.00098C12.4726 2.00098 12.93 2.16836 13.291 2.47346L20.291 8.47246C20.5132 8.66025 20.6918 8.89427 20.8142 9.15817C20.9367 9.42208 21.0001 9.70952 21 10.0005V19.0005C21 19.5309 20.7893 20.0396 20.4142 20.4147C20.0391 20.7897 19.5304 21.0005 19 21.0005H5C4.46957 21.0005 3.96086 20.7897 3.58579 20.4147C3.21071 20.0396 3 19.5309 3 19.0005V10.0005Z"
        stroke="#71717A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M15 21V13C15 12.7348 14.8946 12.4804 14.7071 12.2929C14.5196 12.1054 14.2652 12 14 12H10C9.73478 12 9.48043 12.1054 9.29289 12.2929C9.10536 12.4804 9 12.7348 9 13V21"
        stroke="#71717A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
