import { useEffect, useState } from "react";
import type { Page } from "grapesjs";

import { editorService } from "../editor/index.ts";
import { pagesInstance } from "./pages.instance.ts";
import { pagesService } from "./pages.service.ts";
import { actionProxyService } from "../action-proxy/ActionProxy.service.ts";
import { modalMachineInstance } from "../../../Alpha_O/modules/modal";

import { Button } from "../../../../AlphaO/atoms/Button/Button.tsx";
import { PageActionsModal } from "../../../../AlphaO/molecules/PageActionsModal/PageActionsModal.tsx";

import { pageActionsServiceInstance } from "../../../../AlphaO/molecules/PageActionsModal/PageActionsModal.service.ts";
import { Actions } from "../../../../../assets/svg/Actions.tsx";
import { Close } from "../../../../../assets/svg/Close.tsx";
import { Save } from "../../../../../assets/svg/Save.tsx";
import { Plus } from "../../../../../../assets/svg/plus.tsx";

import useOutsideClick from "../../../../../hooks/useOutsideClick/useOutsideClick.ts";

import "./PagesMenu.css";

const PagesMenuElement = ({ page, selected, isMainPage }: { page: Page; selected: boolean; isMainPage: boolean }) => {
  const [isRenameInputVisible, setIsRenameInputVisible] = useState(false);
  const [pageName, setPageName] = useState(page.getName());
  const ref = useOutsideClick<HTMLLIElement>(() => {
    setIsRenameInputVisible(false);
  });

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    pageActionsServiceInstance.setupActionsModal({
      position: {
        x: ref.current?.getBoundingClientRect().right || 0,
        y: ref.current?.getBoundingClientRect().top || 0,
      },
      setIsRenameInputVisible,
      page,
    });
  };

  return (
    <>
      <li
        key={page.getId()}
        className={`pages-menu__pages-element${selected ? " pages-menu__pages-element--selected" : ""}`}
        onClick={() => pagesInstance.send("SELECT_PAGE", { id: page.getId() })}
        ref={ref}
      >
        {isRenameInputVisible ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              pagesInstance.send("RENAME_PAGE", { id: page.getId(), pageName });
              setIsRenameInputVisible(false);
            }}
            className="pages-menu__rename-form"
          >
            <input
              type="text"
              name="pageName"
              className="pages-menu__rename-input"
              value={pageName}
              onChange={(e) => setPageName(e.target.value)}
              autoFocus
            />
            <Button type="icon" buttonType="submit" iconStart={<Save />} />
          </form>
        ) : (
          <>
            <span className="pages-menu__page-name truncate-text">{page.getName()}</span>
            <button aria-label="show actions" onClick={handleClick}>
              <Actions />
            </button>
          </>
        )}
      </li>
    </>
  );
};

export const PagesMenu = ({ closePanel }: { closePanel: () => void }) => {
  const [pages, setPages] = useState<Page[]>([]);

  const editor = editorService.getEditor();
  const selectedPage = editor?.Pages.getSelected();
  const mainPage = editor?.Pages.getMain();
  const pageCount = pages.length;

  useEffect(() => {
    pagesInstance.start();
    pagesService.subscribe((st) => setPages(st));
    pagesInstance.send("LOAD_PAGES");

    return () => {
      pagesInstance.stop();
      pagesService.unsubscribe(setPages);
    };
  }, []);

  return (
    <>
      <section className="pages-menu">
        <header className="pages-menu__header">
          <h2 className="pages-menu__label">Pages</h2>
          <div className="pages-menu__cta-wrapper">
            <Button
              type="primary"
              onClick={() => {
                if (actionProxyService.canAddPage(pageCount)) {
                  pagesInstance.send("ADD_PAGE");
                } else {
                  modalMachineInstance.send("OPEN", { modalId: "upgradeModal" });
                }
              }}
              iconStart={<Plus />}
            >
              Add page
            </Button>
            <Button type="icon" onClick={closePanel} aria-label="close page panel" iconStart={<Close />} />
          </div>
        </header>
        <ul className="pages-menu__pages-list custom-scrollbar">
          {pages.map((page) => {
            return (
              <PagesMenuElement
                page={page}
                key={page.getId()}
                selected={page.getId() === selectedPage?.getId()}
                isMainPage={mainPage?.getId() === page.getId()}
              />
            );
          })}
        </ul>
      </section>

      <PageActionsModal />
    </>
  );
};
