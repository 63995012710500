import { type CSSProperties } from "react";

type TSizeLaptop = {
  className?: string;
  style?: CSSProperties;
};

export const SizeLaptop = ({ className, style }: TSizeLaptop) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M17.1666 12.5V5.00001C17.1666 4.55798 16.991 4.13406 16.6785 3.8215C16.3659 3.50894 15.942 3.33334 15.5 3.33334H5.49998C5.05795 3.33334 4.63403 3.50894 4.32147 3.8215C4.0089 4.13406 3.83331 4.55798 3.83331 5.00001V12.5M17.1666 12.5H3.83331M17.1666 12.5L18.2333 14.625C18.2976 14.7525 18.328 14.8944 18.3216 15.037C18.3153 15.1796 18.2725 15.3182 18.1972 15.4395C18.1219 15.5608 18.0167 15.6607 17.8917 15.7297C17.7667 15.7986 17.6261 15.8343 17.4833 15.8333H3.51664C3.37388 15.8343 3.23326 15.7986 3.10826 15.7297C2.98326 15.6607 2.87808 15.5608 2.80279 15.4395C2.7275 15.3182 2.68464 15.1796 2.67831 15.037C2.67198 14.8944 2.7024 14.7525 2.76664 14.625L3.83331 12.5"
      stroke="#71717A"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
