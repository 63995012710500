import { TLocalColors } from "./LocalColors.types";

import "./LocalColors.css";

export const LocalColors: TLocalColors = ({ colors }) => {
  const formatLabel = (label: string) => {
    return label.replace(/^--/, "").replace(/_/g, " ");
  };

  const sortEntriesByKeyName = (a: [string, any], b: [string, any]) => {
    if (a[0] < b[0]) return -1;
    if (a[0] > b[0]) return 1;
    return 0;
  };

  const sortedColors = Object.entries(colors).sort(sortEntriesByKeyName);

  if (!colors || Object.keys(colors).length === 0) return null;

  return (
    <div className={`local-colors`}>
      <ul className="local-colors__list">
        {sortedColors.map(([key, value]) => (
          <li className="local-colors__variable" key={key}>
            <label className="local-colors__label">{formatLabel(key)}</label>
            <span className="local-colors__variable-value">
              <div className="local-colors__color-value" style={{ background: value }}></div>
              <span className="local-colors__color-name truncate-text">{value}</span>
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};
