import { assign, createMachine } from "xstate";
import { BlockProperties, Editor, grapesjs } from "grapesjs";
import { editorConfig } from "../../editor.config.ts";
import { EditorService } from "./editor.service.ts";
import { pagesService } from "../pages/pages.service.ts";
import { projectsService } from "../../../../../machines/projects/projects.instance.ts";
import { assetManagerInstance, assetManagerService } from "../asset-manager";
import { modalMachineInstance } from "../modal/index.ts";
import { extractComponents } from "../../utils/extract-components.ts";
import { extractVariables } from "../../utils/extract-variables.ts";
import { UserService } from "../../../../../services/UserService/UserService.ts";
import { PageScripts, ProjectPublish, PublishPage } from "../../../../../types/types.ts";
import { replaceScriptTag } from "../../utils/replaceScriptTag.ts";
import { CODEJET_BADGE_SCRIPT } from "../../utils/codejetBadgeScript.ts";
import { loadProject, fallbackLoadProject, handleCustomCode, addScriptsToEditor } from "./helpers.ts";

const userService = UserService.getInstance();

export type EditorContext = {
  editor?: Editor;
  service: EditorService;
  domainToUnpublish: "subdomain" | "customDomain";
};
export const editorMachine = (service: EditorService) =>
  createMachine<EditorContext>(
    {
      /** @xstate-layout N4IgpgJg5mDOIC5QGEBKBRAggFXQfQFUBldVPAMQHlUBZAOiO01WwGIBtABgF1FQAHAPawAlgBcRggHZ8QAD0QBGACzK6ADgBM6xYoDsmzgDYjATlPqjAGhABPJQGZFGsw6169p5Z3UBWAL7+NmhYuIQkZFS0dOTo2MgAEngAigTojACSlAByRKwQ0mB0IlIAboIA1kUhOPjEpBTU9LHxSanp2Fm5CCXlAMYAhhLSXNyjskKiwzJI8ojKRmqmDnrKpoqceoq+yirKNvYIXhqm2pp66uoOvtu+eoHBGLXhDVHNcYkpaZk5eWAATv9BP86PwADZDABmwIAtnQamF6pEmjEPm1vp1fj0yoJBtNRuNZpNxJIZqAFAgFksVmsNlsdnsDogjA5OHRfL5zL5Wb4jOo9EYHiAEXUIo1ou0frlWERMAA1fCYXIAdVIhIEwhJ0lkFL8ahZ6mMmx2nB8miZCF8miMdE43lWpiM1sUmgcgqCwqeiLFbzoksx0uy6AAGtgvh0uuqQMTpjrEHq6KZjJp9G5tEYLhbdr5E9yXeY6ZZVkKRS9kRKMV08gAFDByrLEcNSqMx0lxhAJpNO1NaIvqC36NTKPOGLzWvnKEte0WvFGyhV4JVEVWofKFYo4qrw6dl8X0eeKlWkbH9Iakgk8Caa2OzXXHZTqZROF1GO7D812eZXdlu3ycTQ7H+r6KFOoQzuW9AZEQFAZNkmAADJNgGHCXkS15trejj6HQygplopp+CY-afpSdx0BciwsnonCKI+tEBB6pZInudBQTBcGIf6XQcIovBoVMGHklheg4Xhhg+LyfJZro5GWAsnB3Ga5igc8zG+kGoZIdxBRSEUvSVNUO5qSiGlhlxOQnriZ4jDwLbodqmEIIoTimHQ1ocuoFhJvoqwWucDiJgKkkrB4r6aCp3qztEtboPWlCNuZ2RrrpG7lFuTE+iiMVxQllYWfpeLnrZqEagJDlCR2RjODcHlbGczkWjoOHmOYbgCk6VoMY8YG7r6MXVswh7LqQeQ6Xpm6GT1xnRRgA0YIuR6oEQlmFTZYwldG9lknMTmrJo7JOu+2zeA4pxZmRnBugs5zaOYPgReBLFLiu0FPAAIgAmjK6DZG9C3DUtdlldtFIpqdOF8nop18qYkm+OdObcpRmxXNolhdZ6U2ZdEJC-f9L3JeNaWTap2P7j9f3PSNK3WVIF58aVWog4gAHg3cOjXGmdpeFmhi2pynl7KoZheA9vVzhT+MjawAJAiC4JQrC25Y1F5N41TS00-ixUM5twPtqzrns853L4Ws+wkTcAU0ay9EbFbIFClIggQHAsgZarV7645AC01gkX7tqmqavh+JsejclDGMexBDBMCwXtM+2uEDqyLjLHyOyaK6OwOGL03vK0Wm-InN4Va6rmnLRHiGJcnkDpsdBul4l1Vd4hrWvnZN+nluSl4JO0mCJVePrDEftwOig2lVY5uMYnmbF3qsMPKQ0rv35U7YWOHcsapzjwKWarLmTg3Pol0CtsS+x2x5CwQhxfZBvzNORsbKGgYKb6NnSbEYcuw2mHM+S+yxtjqGvixUyj9n7tm2BsOgh1TDUVoqcMKflLh0F0EgiO5gPDtwgX1OsDZoKJRgY5beQC97nB2IfEisMEFPmtEmM+LpuQEKyrNQaUslpkIqnAtkiDkGeXch+f+Dh9pI25DdWG11o5GW7hrV6WBPq8K3rDfaCx+TnFOJyXQWZxE-lfOIgwMi27sJxpLRRqjQbiJzNsQ6eY3QrH9mIiRV0HBPmwRcSw5jmiwSggkdAb1rEsyhs4R0-JqKcHWBmeGJFVBsiRqoZQeC-DUTkSrWOpBUDUBCQgfy4TIZRJiRHC0NxXLWlZNE9GtEHB50CP4IAA */
      predictableActionArguments: true,
      id: "ALPHA_O",
      initial: "START",
      context: {
        service,
        domainToUnpublish: "subdomain",
      },
      states: {
        START: {
          invoke: {
            src: "initEditor",
            onDone: {
              target: "MOUNT_BLOCKS",
            },
          },
        },

        MOUNT_BLOCKS: {
          invoke: {
            src: "mountBlocks",
            onDone: {
              target: "MOUNT_COMPONENTS",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        MOUNT_COMPONENTS: {
          invoke: {
            src: "mountComponents",
            onDone: {
              target: "INIT_ASSET_MANAGER_SERVICE",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        INIT_ASSET_MANAGER_SERVICE: {
          invoke: {
            src: "initAssetManagerService",
            onDone: {
              target: "SEND_PAGES_TO_PAGE_SERVICE",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        SEND_PAGES_TO_PAGE_SERVICE: {
          invoke: {
            src: "sendPagesToPageService",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        READY: {
          entry: "notify",
          on: {
            DRAG_START: "DRAG_START",
            DRAG_END: "DRAG_END",
            SHOW_ADD_NEW_ELEMENT_PANEL: "SHOW_ADD_NEW_ELEMENT_PANEL",
            CLOSE_ADD_NEW_ELEMENT_PANEL: "CLOSE_ADD_NEW_ELEMENT_PANEL",
            PUBLISH_PROJECT: "PUBLISH_PROJECT",
            PUBLISH_UPDATES: "PUBLISH_UPDATES",
            UNPUBLISH_PROJECT: "UNPUBLISH_PROJECT",
            DOMAIN_TO_UNPUBLISH: "DOMAIN_TO_UNPUBLISH",
            SAVE_CUSTOM_DOMAIN: "SAVE_CUSTOM_DOMAIN",
            CHANGE_DEVICE_TYPE: "CHANGE_DEVICE_TYPE",
            TOGGLE_PREVIEW: "TOGGLE_PREVIEW",
            UNDO: "UNDO",
            REDO: "REDO",
            TOGGLE_FULLSCREEN: "TOGGLE_FULLSCREEN",
            TOGGLE_OUTLINE: "TOGGLE_OUTLINE",
          },
        },

        PUBLISH_UPDATES: {
          invoke: {
            src: "publishUpdates",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        PUBLISH_PROJECT: {
          invoke: {
            src: "publishProject",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        DOMAIN_TO_UNPUBLISH: {
          entry: assign({
            domainToUnpublish: (context, event) => event.domainToUnpublish,
          }),
          always: [
            {
              target: "READY",
            },
          ],
        },

        UNPUBLISH_PROJECT: {
          invoke: {
            src: "unpublishProject",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        SAVE_CUSTOM_DOMAIN: {
          invoke: {
            src: "saveCustomDomain",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        CHANGE_DEVICE_TYPE: {
          invoke: {
            src: "changeDeviceType",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        SHOW_ADD_NEW_ELEMENT_PANEL: {
          invoke: {
            src: "showAddNewElementPanel",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        CLOSE_ADD_NEW_ELEMENT_PANEL: {
          invoke: {
            src: "hideAddNewElementPanel",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        DRAG_END: {
          invoke: {
            src: "dragEnd",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        DRAG_START: {
          invoke: {
            src: "dragStart",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },
        TOGGLE_PREVIEW: {
          invoke: {
            src: "togglePreview",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        UNDO: {
          invoke: {
            src: "undo",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        REDO: {
          invoke: {
            src: "redo",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        TOGGLE_FULLSCREEN: {
          invoke: {
            src: "toggleFullscreen",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        TOGGLE_OUTLINE: {
          invoke: {
            src: "toggleOutline",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        FINISH: {
          type: "final",
        },

        ERROR: {},
        //
      },
    },
    {
      actions: {
        notify: (context) => {
          context.service.notifySubscribers();
        },
      },
      services: {
        publishUpdates: async ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          const activeProject = projectsService.getActiveProject();
          if (!activeProject) {
            console.log("No active project");
            return Promise.resolve();
          }

          const canPurchase = userService.canPurchaseSubscription();
          const editorPages = editor.Pages.getAll();

          const projectPublish: ProjectPublish = {
            projectId: activeProject?.projectId,
            pages: [],
            variables: "",
            // component: "",
            scripts: [],
          };

          const history = await projectsService.getHistory(activeProject.projectId);
          const customCode = (history.metadata.customCode as string) || undefined;
          const customHead = (history.metadata.customHead as string) || undefined;
          const title = (history.metadata.name as string) || undefined;
          const description = (history.metadata.description as string) || undefined;

          // gathering pages data

          editorPages.forEach((page, index) => {
            const pageMainComponent = page.getMainComponent();
            const pageHtml = editor.getHtml({ component: pageMainComponent });
            const pageCss = editor.getCss({ component: pageMainComponent }) || "";

            const globalVariablesDepth = index !== 0 ? "../" : "./";

            const pagePublishData: PublishPage = {
              frameId: page.getId(),
              name: page.getName(),
              component: "",
              styles: "",
            };

            // js part

            const customTemplate = `
            document.addEventListener("DOMContentLoaded", function() {
              ${customCode}
            });
          `;

            const isCustomCode = customCode !== undefined;

            // HTML part

            //TODO manipulate html before save
            let projectHead = `
                      <head>
                      <title>${title} | Powered by Codejet.ai</title>
                      <meta name="description" content="${description}">
                      <meta charset="UTF-8">
                      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                      <link rel="stylesheet" href="${globalVariablesDepth}reset.css" />
                      <link rel="stylesheet" href="./styles.css" />
                      <link rel="stylesheet" href="${globalVariablesDepth}variables.css" />
                      <link rel="stylesheet" href="${globalVariablesDepth}components.css" />
                      ${customHead || ""}
                      ${canPurchase ? CODEJET_BADGE_SCRIPT : ""}
                      </head>`;

            // editor.Canvas.getDocument().head.outerHTML.match(/<head>[\s\S]*<\/head>/)?.[0] || "<head></head>";
            // opening tags for html
            projectHead = "<html>" + projectHead;
            // closing tags for html
            const projectCloseTag = "\n</html>";

            if (customCode) {
              const script: PageScripts = {
                fileName: "index.js",
                content: customTemplate,
              };
              projectPublish.scripts.push(script);
            }

            const projectBody = isCustomCode ? replaceScriptTag(pageHtml, "index.js", globalVariablesDepth) : pageHtml;
            // remove class="iframe-no-pointer" from Video component
            const clearedBody = projectBody.replace(/class="iframe-no-pointer"/g, "");

            pagePublishData.component = projectHead + clearedBody + projectCloseTag;

            // CSS part
            const { variables, styles: stylesAfterRemoveVariables } = extractVariables(pageCss, [
              "local-styles-variables",
              "local-text-styles-variables",
            ]);

            // TODO if we change components we need to update components.css
            const { components, styles: stylesAfterRemoveComponents } = extractComponents(stylesAfterRemoveVariables);

            if (index === 0) {
              projectPublish.variables = variables;
              // projectPublish.components = components;
            }
            pagePublishData.styles = stylesAfterRemoveComponents;

            // add page to project
            projectPublish.pages.push(pagePublishData);

            // await projectsService.saveProjectHtml(activeProject.projectId, activeProject.html);
            // await projectsService.saveProjectScript(activeProject.projectId, customTemplate || "");
          });

          try {
            // push changes to server
            await projectsService.publishProject(projectPublish);
            // update project meta data
            await projectsService.setSynced(activeProject?.projectId, true);
            // invalidate domain
            await projectsService.invalidateDomain(activeProject.projectId);
          } catch (error) {
            console.error(error);
          }

          return Promise.resolve();
        },

        publishProject: async ({ service }, { customDomain }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();
          const activeProject = projectsService.getActiveProject();
          if (!activeProject) {
            console.log("No active project");
            return Promise.resolve();
          }

          const canPurchase = userService.canPurchaseSubscription();
          const editorPages = editor.Pages.getAll();

          const projectPublish: ProjectPublish = {
            projectId: activeProject?.projectId,
            pages: [],
            variables: "",
            // component: "",
            scripts: [],
          };

          const history = await projectsService.getHistory(activeProject.projectId);
          const customCode = (history.metadata.customCode as string) || undefined;
          const customHead = (history.metadata.customHead as string) || undefined;
          const title = (history.metadata.name as string) || undefined;
          const description = (history.metadata.description as string) || undefined;

          // gathering pages data

          editorPages.forEach((page, index) => {
            const pageMainComponent = page.getMainComponent();
            const pageHtml = editor.getHtml({ component: pageMainComponent });
            const pageCss = editor.getCss({ component: pageMainComponent }) || "";

            const globalVariablesDepth = index !== 0 ? "../" : "./";

            const pagePublishData: PublishPage = {
              frameId: page.getId(),
              name: page.getName(),
              component: "",
              styles: "",
            };

            // HTML part

            //TODO manipulate html before save
            let projectHead = `
                      <head>
                      <title>${title} | Powered by Codejet.ai</title>
                      <meta name="description" content="${description}">
                      <meta charset="UTF-8">
                      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                      <link rel="stylesheet" href="${globalVariablesDepth}reset.css" />
                      <link rel="stylesheet" href="./styles.css" />
                      <link rel="stylesheet" href="${globalVariablesDepth}variables.css" />
                      <link rel="stylesheet" href="${globalVariablesDepth}components.css" />
                      ${customHead || ""}
                      ${canPurchase ? CODEJET_BADGE_SCRIPT : ""}
                      </head>`;
            // editor.Canvas.getDocument().head.outerHTML.match(/<head>[\s\S]*<\/head>/)?.[0] || "<head></head>";
            // opening tags for html
            projectHead = "<html>" + projectHead;
            // closing tags for html
            const projectCloseTag = "\n</html>";

            const customTemplate = `
                        document.addEventListener("DOMContentLoaded", function() {
                          ${customCode}
                        });
                      `;

            if (customCode) {
              const script: PageScripts = {
                fileName: "index.js",
                content: customTemplate,
              };
              projectPublish.scripts.push(script);
            }

            pagePublishData.component = projectHead + pageHtml + projectCloseTag;

            const isCustomCode = customCode !== undefined;

            const projectBody = isCustomCode ? replaceScriptTag(pageHtml, "index.js", globalVariablesDepth) : pageHtml;
            // remove class="iframe-no-pointer" from Video component
            const clearedBody = projectBody.replace(/class="iframe-no-pointer"/g, "");

            pagePublishData.component = projectHead + clearedBody + projectCloseTag;

            // CSS part
            const { variables, styles: stylesAfterRemoveVariables } = extractVariables(pageCss, [
              "local-styles-variables",
              "local-text-styles-variables",
            ]);

            const { components, styles: stylesAfterRemoveComponents } = extractComponents(stylesAfterRemoveVariables);

            if (index === 0) {
              projectPublish.variables = variables;
              // projectPublish.components = components;
            }

            pagePublishData.styles = stylesAfterRemoveComponents;

            // add page to project
            projectPublish.pages.push(pagePublishData);

            // await projectsService.saveProjectHtml(activeProject.projectId, activeProject.html);
            // await projectsService.saveProjectScript(activeProject.projectId, customTemplate || "");
          });

          try {
            if (customDomain) {
              projectPublish["customDomain"] = activeProject.customDomain.address;
            }

            await projectsService.publishProject(projectPublish);
            await projectsService.setSynced(activeProject?.projectId, true);
            await projectsService.invalidateDomain(activeProject.projectId);
            modalMachineInstance.send("CHANGE_VIEW", { viewId: "domains" });
          } catch (error) {
            console.error(error);
          }

          return Promise.resolve();
        },

        unpublishProject: async ({ service, domainToUnpublish }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          const activeProject = projectsService.getActiveProject();

          if (activeProject) {
            try {
              let projectData;
              if (domainToUnpublish === "customDomain") {
                projectData = {
                  projectId: activeProject.projectId,
                  customDomain: activeProject.customDomain.address,
                };
              } else {
                projectData = {
                  projectId: activeProject.projectId,
                };
              }

              await projectsService.unpublishProject(projectData);
              modalMachineInstance.send("CHANGE_VIEW", { viewId: "domains" });
            } catch (error) {
              console.error(error);
            }
          } else {
            console.log("No active project");
          }

          return Promise.resolve();
        },

        saveCustomDomain: async ({ service }, { customDomain }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          const activeProject = projectsService.getActiveProject();

          if (activeProject) {
            await projectsService.updateProjectData(activeProject.projectId, { customDomain });
          } else {
            console.log("No active project");
          }

          return Promise.resolve();
        },

        sendPagesToPageService: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          const pages = editor.Pages.getAll();
          //Editor has be initialised to send pages to pagesMachine
          pagesService.state = pages;
          pagesService.notifySubscribers();

          return Promise.resolve();
        },

        showAddNewElementPanel: ({ service }) => {
          service.addNewElementPanelVisible = true;
          //
          // return Promise.resolve();
          return Promise.resolve();
        },

        hideAddNewElementPanel: ({ service }) => {
          service.addNewElementPanelVisible = false;

          return Promise.resolve();
        },

        // saveProject: async ({ service }) => {
        //TODO this is to save project as HTML and CSS when publish
        // const editor = service.getEditor();
        // if (!editor) return Promise.resolve();
        //
        // const activeProject = projectsService.getActiveProject();
        //
        // if (activeProject) {
        //   activeProject.html = activeProject.projectHead + editor.getHtml() + activeProject.projectCloseTag;
        //   projectsService.saveProjectHtml(activeProject.projectId, activeProject.html);
        //   projectsService.saveProjectCss(activeProject.projectId, editor.getCss() || "");
        // } else {
        //   console.log("No active project");
        // }

        // return Promise.resolve();
        // },

        initEditor: (context) => {
          const editor = grapesjs.init(editorConfig);
          context.service.setEditor(editor);

          editor.on("block:custom", (props) => {
            service.setBlocksModel(props);
          });

          editor.on("layer:custom", () => {
            const layerManagerContainer = document.getElementById("gjs-layer-manager");
            if (layerManagerContainer) {
              layerManagerContainer.appendChild(editor.LayerManager.render());
            }
          });

          editor.on("load", async () => {
            const activeProject = projectsService.getActiveProject();
            if (activeProject !== undefined) {
              try {
                await loadProject(editor, activeProject, projectsService);
              } catch (e) {
                console.error("[:load] - ", e);
                await fallbackLoadProject(editor, activeProject, projectsService);
              } finally {
                context.service.setIsLoaded(true);
                const pages = editor.Pages.getAll();
                pagesService.state = pages;
              }
            }

            service.notifySubscribers();
          });

          const initPageScripts = async () => {
            const head = editor.Canvas.getDocument()?.head;
            const canAddCustomHead = head && head?.innerHTML === "";
            if (!canAddCustomHead) return;
            try {
              const activeProject = projectsService.getActiveProject();
              if (activeProject) {
                addScriptsToEditor(editor, projectsService);
                await handleCustomCode(editor, activeProject, projectsService);
              } else {
                console.error("[canvas:frame:load] - No active project");
              }
            } catch (e) {
              console.error("[canvas:frame:load] - ", e);
            }
          };

          editor.on("canvas:frame:load", async () => {
            await initPageScripts();
          });

          editor.on("page:select", async () => {
            await initPageScripts();
          });

          //TODO move to plugin and create function to inject projectService
          editor.Storage.add("remote", {
            async load() {
              //get active project
              const activeProject = projectsService.getActiveProject();

              if (activeProject) {
                //first load from local storage
                //TODO check how to delete all data from local storage when project is deleted or user logs out
                const localData = sessionStorage.getItem("project-config-" + activeProject?.projectId);
                if (localData) {
                  const data = JSON.parse(localData);
                  //Add scripts for next step
                  //TODO add meta field for projectService
                  projectsService.scripts = data.scripts;
                  return data.config;
                }

                // if no local storage load from history
                // if backend dont find hostory return object with config as {}, and metadata from project its needed for adding scripts later on
                const history = await projectsService.getHistory(activeProject?.projectId);

                if (Object.keys(history.config).length === 0) {
                  throw new Error("No config in history");
                }

                if (history) {
                  //Add scripts for next step
                  //TODO add meta field for projectService
                  projectsService.scripts = history.scripts;
                  return history.config;
                }
                throw new Error("No history found");
              }
            },

            async store(data) {
              //get active project ID and save project JSON
              //TODO locally store project JSON
              //TODO send only patch on JSON
              //this is a bit tricky but it's only way to use autosave feature
              const activeProject = projectsService.getActiveProject();

              //get all scripts from editor head as strings
              // const scripts = Array.from(editor.Canvas.getDocument().head.children).map((script) => script.outerHTML);

              const scripts = projectsService.scripts;
              if (activeProject) {
                // console.log("saving to session storage");
                //save to local storage
                sessionStorage.setItem(
                  "project-config-" + activeProject?.projectId,
                  JSON.stringify({
                    scripts,
                    config: data,
                  })
                );
                //if history not empty load history
                await projectsService.saveHistory(activeProject?.projectId, { config: data, scripts });
                if (activeProject.isSynced !== false) {
                  await projectsService.setSynced(activeProject?.projectId, false);
                }
              }
            },
          });

          // TODO move to own service
          // editor.on("selector:custom", (props) => {
          //   console.log("selector:custom", props);
          //   selectorManagerInstance.setSelectorManager(editor.Selectors);
          //   selectorManagerInstance.open();
          // });
          // editor.on("component:selected", (props) => {
          //   console.log(props);
          //   selectorManagerInstance.selectComponent(props);
          // });
          // editor.on("component:deselected", (props) => {
          //   selectorManagerInstance.deselectComponent();
          // });

          return Promise.resolve();
        },

        initAssetManagerService: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          editor.on("asset:custom", (props) => {
            assetManagerService.setAssetManager(props);
            assetManagerInstance.send("INIT");
          });

          editor.on("component:selected", (props) => {
            if (props.attributes.tagName === "img" || props.attributes.tagName === "video") {
              assetManagerInstance.send("SELECT_COMPONENT", { component: props });
            }
          });

          editor.on("component:deselected", (props) => {
            if (props.attributes.tagName === "img" || props.attributes.tagName === "video") {
              assetManagerInstance.send("DESELECT_COMPONENT");
            }
          });

          return Promise.resolve();
        },

        mountComponents: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          for (const componentDef of service.componentsDef) {
            editor.Components.addType(componentDef.id, componentDef.props);
          }

          return Promise.resolve();
        },

        mountBlocks: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          //mount block from service to editor
          for (const block of service.blocks) {
            if (block.id) {
              editor.Blocks.add(block.id, block);
            }
          }

          //Load blocks added from plugins to service
          const blockMap = editor.Blocks.getAllMap();
          for (const key in blockMap) {
            if (service.blocks.find((block) => block.id === key)) continue;
            service.blocks.push(blockMap[key].attributes as BlockProperties);
          }

          return Promise.resolve();
        },

        //appendService
        dragEnd: ({ service }, { id }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          const block = service.blocksModel.blocks.find((block) => block.id === id);
          if (block) {
            service.blocksModel.dragStop(block);
          }
          return Promise.resolve();
        },

        dragStart: ({ service }, { id }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          const block = service.blocksModel.blocks.find((block) => block.id === id);
          if (block) {
            service.blocksModel.dragStart(block);
          }
          return Promise.resolve();
        },

        changeDeviceType: ({ service }, { deviceType }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          editor.setDevice(deviceType);
          return Promise.resolve();
        },

        togglePreview: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          if (editor.Commands.isActive("preview")) {
            editor.DomComponents.getWrapper()?.onAll((comp) => comp.is("text") && comp.set({ editable: true }));
            // add pointer events none to video iframes
            const iframes = editor.getWrapper()?.find(".video-wrapper iframe");
            iframes?.forEach((iframe) => {
              iframe.addClass("iframe-no-pointer");
            });
            editor.stopCommand("preview");
          } else {
            // remove pointer events from video iframes
            const iframes = editor.getWrapper()?.find(".video-wrapper iframe");
            iframes?.forEach((iframe) => {
              iframe.removeClass("iframe-no-pointer");
            });

            editor.stopCommand("sw-visibility");
            editor.runCommand("preview");
            editor.DomComponents.getWrapper()?.onAll((comp) => comp.is("text") && comp.set({ editable: false }));
          }

          return Promise.resolve();
        },

        undo: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          editor.UndoManager.undo();

          return Promise.resolve();
        },

        redo: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          editor.UndoManager.redo();

          return Promise.resolve();
        },

        toggleFullscreen: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          if (editor.Commands.isActive("fullscreen")) {
            editor.stopCommand("fullscreen");
          } else {
            editor.runCommand("fullscreen", { target: ".alpha-o" });
          }

          return Promise.resolve();
        },

        toggleOutline: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          if (editor.Commands.isActive("sw-visibility")) {
            editor.stopCommand("sw-visibility");
          } else {
            editor.runCommand("sw-visibility");
          }

          return Promise.resolve();
        },
      },

      guards: {
        //appendGuards
      },
    }
  );
