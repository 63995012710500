import { useState, useEffect } from "react";
// import dayjs from "dayjs";fire

import { PaymentsService } from "../../../services/Payments/Payments";
import { UserService } from "../../../services/UserService/UserService";
import { projectsInstance, projectsService } from "../../../machines/projects/projects.instance";

import { ButtonNew } from "../../atoms/ButtonNew";
import { Plan } from "../../Dashboard/molecules/Plan";
import { Switch } from "../../Dashboard/atoms/Switch";
import { Starter } from "../../../assets/svg/Starter";
import { EssentialPlan } from "../../../assets/svg/EssentialPlan";
import { ProPlan } from "../../../assets/svg/ProPlan";
import { CreditCard } from "../../../assets/svg/CreditCard";
import { GridMiniIcon } from "../../../../assets/svg/grid-mini-icon";
import { Button } from "../../Dashboard/atoms/Button";
import { ArrowUpRight } from "../../../assets/svg/ArrowUpRight";

import { useAsyncAction } from "../../../utils/useAsyncAction/useAsyncAction";
import { formatPeriodDate } from "../../../utils/formatPeriodDate/formatPeriodDate";

import { TPlanSettings } from "./PlanSettings.types";
import { type Project, SubscriptionStatus } from "../../../types/types";

import "./PlanSettings.css";

const userService = UserService.getInstance();

const switchInitialValue = (userService: UserService) => {
  if (userService.periodType === "month") {
    return false;
  }
  if (userService.periodType === "annual") {
    return true;
  }
};

const lockedStatuses = ["incomplete", "incomplete_expired", "past_due", "unpaid"];

export const PlanSettings: TPlanSettings = () => {
  const [activeProject, setActiveProject] = useState<Project>();
  const [_, setState] = useState(userService.state);

  const essentialPlanPriceID = import.meta.env.VITE_ESSENTIAL_PRICE_ID;
  const proPlanPriceID = import.meta.env.VITE_PRO_PRICE_ID;
  const { priceId = "", status = SubscriptionStatus.active, nextPlanPriceId } = activeProject?.subscription || {};
  const isActiveSubscription =
    priceId === import.meta.env.VITE_ESSENTIAL_PRICE_ID || priceId === import.meta.env.VITE_PRO_PRICE_ID;

  const isStarter = priceId === "";
  const isEssential = priceId === essentialPlanPriceID;
  const isPro = priceId === proPlanPriceID;

  // TODO Lukasz change logic when backend is ready
  const [isYearly, setIsYearly] = useState(switchInitialValue(userService));
  const { isLoading, handleAsyncAction } = useAsyncAction();

  useEffect(() => {
    const project = projectsInstance.getSnapshot().context.service.getActiveProject();
    setActiveProject(project);
  }, []);

  useEffect(() => {
    userService.subscribe(setState);
    return () => userService.unsubscribe(setState);
  }, []);

  const projectAddress = () => {
    if (activeProject?.customDomain?.published) {
      return activeProject?.customDomain?.address;
    }

    return activeProject?.subdomain?.address;
  };

  const subscriptionTerminateDate = (priceIdToCheck: string, subscription: Project["subscription"] | undefined) => {
    if (!subscription) return "";
    const { priceId, isClosed, currentPeriodEnd } = subscription;
    if (priceIdToCheck !== priceId || !isClosed) {
      return "";
    }

    return formatPeriodDate(currentPeriodEnd || 0);
  };
  const newSubscriptionStartDate = (priceIdToCheck: string, subscription: Project["subscription"] | undefined) => {
    if (!subscription) return "";
    const { isClosed, currentPeriodEnd, nextPlanPriceId } = subscription;
    if (priceIdToCheck !== nextPlanPriceId || !isClosed) {
      return "";
    }

    return formatPeriodDate(currentPeriodEnd || 0);
  };

  const handleEssentialPlanButton = () => {
    if (isPro) {
      return async () =>
        await handleAsyncAction(() =>
          projectsService.downgradeProjectSubscription(activeProject?.projectId || "", essentialPlanPriceID)
        );
    }

    if ((isEssential && status === "active") || nextPlanPriceId === essentialPlanPriceID) {
      return undefined;
    }

    if (isEssential && lockedStatuses.includes(status)) {
      async () => await PaymentsService.createPortalSession();
    }

    return async () =>
      await PaymentsService.createCheckoutSession(essentialPlanPriceID, "subscription", activeProject?.projectId || "");
  };

  const planData = {
    monthly: [
      {
        title: "Starter",
        icon: <Starter />,
        price: "$0",
        frequency: "/per month",
        description: "For hobby projects and quick staging websites",
        publishingOn: "Codejet.site domain",
        featuresTitle: "An entry-level site:",
        features: ["2 published pages", "2 GB bandwidth", "100 form submission (lifetime)", "Regular backups"],
        extensionsTitle: "Codejet’s extensions:",
        extensions: ["Access to Codejet Design System", "Sync Figma to Codejet"],
        actionButtonLabel: isStarter ? "Current plan" : "Downgrade to Starter",
        closeDate: subscriptionTerminateDate("", activeProject?.subscription),
        startDate: newSubscriptionStartDate("", activeProject?.subscription),
        actionButtonOnClick: isStarter
          ? undefined
          : async () =>
              await handleAsyncAction(() => {
                return projectsService.downgradeProjectSubscription(activeProject?.projectId || "", "");
              }),
        actionCurrentPlan: isStarter,
        actionButtonLoading: isLoading,
      },
      {
        title: "Essential",
        icon: <EssentialPlan />,
        price: "$10",
        frequency: "/per month",
        description: "For relatively simple, static websites",
        publishingOn: "Custom domain",
        featuresTitle: "A basic site:",
        features: ["50 published pages", "20 GB bandwidth", "500 form submission (monthly)", "Regular backups"],
        extensionsTitle: "Codejet’s extensions:",
        extensions: ["Access to Codejet Design System", "Sync Figma to Codejet"],
        closeDate: subscriptionTerminateDate(essentialPlanPriceID, activeProject?.subscription),
        startDate: newSubscriptionStartDate(essentialPlanPriceID, activeProject?.subscription),
        actionButtonLabel: isEssential ? "Current plan" : isPro ? "Downgrade to Essential" : "Upgrade to Essential",
        actionButtonOnClick: handleEssentialPlanButton(),
        actionCurrentPlan: isEssential,
        actionButtonLoading: isLoading,
      },
      {
        title: "Pro",
        icon: <ProPlan />,
        price: "$29",
        frequency: "/per month",
        description: "For larger, more complex websites",
        publishingOn: "Custom domain",
        featuresTitle: "A business site:",
        features: ["100 published pages", "100 GB bandwidth", "Unlimited form submission", "Regular backups"],
        extensionsTitle: "Codejet’s extensions:",
        extensions: ["AI code generation in HTML", "Access to Codejet Design System", "Sync Figma to Codejet"],
        actionButtonLabel: isPro ? "Current plan" : "Upgrade to Business",
        closeDate: subscriptionTerminateDate(proPlanPriceID, activeProject?.subscription),
        startDate: newSubscriptionStartDate(proPlanPriceID, activeProject?.subscription),
        actionButtonOnClick:
          isPro && status === "active"
            ? undefined
            : async () =>
                await PaymentsService.createCheckoutSession(
                  proPlanPriceID,
                  "subscription",
                  activeProject?.projectId || ""
                ),
        actionCurrentPlan: isPro,
        actionButtonLoading: isLoading,
      },
    ],
    yearly: [
      {
        title: "Starter",
        icon: <Starter />,
        price: "$0",
        frequency: "/per month",
        description: "For hobby projects and quick staging websites",
        publishingOn: "Codejet.site domain",
        featuresTitle: "An entry-level site:",
        features: ["2 published pages", "2 GB bandwidth", "100 form submission (lifetime)", "Regular backups"],
        extensionsTitle: "Codejet’s extensions:",
        extensions: ["Access to Codejet Design System", "Sync Figma to Codejet"],
        actionButtonLabel: isStarter ? "Current plan" : "Downgrade to Starter",
        closeDate: subscriptionTerminateDate("", activeProject?.subscription),
        startDate: newSubscriptionStartDate("", activeProject?.subscription),
        actionButtonOnClick: isStarter
          ? undefined
          : async () =>
              await handleAsyncAction(() =>
                projectsService.downgradeProjectSubscription(activeProject?.projectId || "", "")
              ),
        actionCurrentPlan: isStarter,
        actionButtonLoading: isLoading,
      },
      {
        title: "Essential",
        icon: <EssentialPlan />,
        price: "$8",
        frequency: "/per month",
        description: "For relatively simple, static websites",
        publishingOn: "Custom domain",
        featuresTitle: "A basic site:",
        features: ["50 published pages", "20 GB bandwidth", "500 form submission (monthly)", "Regular backups"],
        extensionsTitle: "Codejet’s extensions:",
        extensions: ["Access to Codejet Design System", "Sync Figma to Codejet"],
        renewalDate: formatPeriodDate(userService.data?.CURRENT_PERIOD_END || 0),
        actionButtonLabel: isEssential ? "Current plan" : isPro ? "Downgrade to Essential" : "Upgrade to Essential",
        closeDate: subscriptionTerminateDate("", activeProject?.subscription),
        startDate: newSubscriptionStartDate("", activeProject?.subscription),
        actionButtonOnClick: handleEssentialPlanButton(),
        actionCurrentPlan: isEssential,
        actionButtonLoading: isLoading,
      },
      {
        title: "Pro",
        icon: <ProPlan />,
        price: "$25",
        frequency: "/per month",
        description: "For larger, more complex websites",
        publishingOn: "Custom domain",
        featuresTitle: "A business site:",
        features: ["100 published pages", "100 GB bandwidth", "Unlimited form submission", "Regular backups"],
        extensionsTitle: "Codejet’s extensions:",
        extensions: ["AI code generation in HTML", "Access to Codejet Design System", "Sync Figma to Codejet"],
        actionButtonLabel: isPro ? "Current plan" : "Upgrade to Business",
        closeDate: subscriptionTerminateDate("", activeProject?.subscription),
        startDate: newSubscriptionStartDate("", activeProject?.subscription),
        actionButtonOnClick:
          isPro && status === "active"
            ? async () => await PaymentsService.createPortalSession()
            : async () =>
                await PaymentsService.createCheckoutSession(
                  proPlanPriceID,
                  "subscription",
                  activeProject?.projectId || ""
                ),
        actionCurrentPlan: isPro,
        actionButtonLoading: isLoading,
      },
    ],
  };

  return (
    <>
      <div className="plan-settings__content container">
        <header className="plan-settings__heading">
          <div className="plan-settings__grid">
            <div className="plan-settings__typography">
              <a className="project-settings__link" href={projectAddress()}>
                {activeProject?.name}
                <ArrowUpRight />
              </a>

              <h2 className="plan-settings__title">Plans</h2>
              <p className="plan-settings__subtitle">
                You can upgrade your plan at any time to publish to add a custom domain, get more traffic, and unlock
                additional features to create more powerful, custom websites.
              </p>
            </div>
            <Button
              active
              className="project-settings__header-container-btn"
              onClick={() => {
                projectsInstance.send("SELECT_PROJECT", { projectId: activeProject?.projectId });
              }}
            >
              <GridMiniIcon /> Visual Editor
            </Button>
          </div>
          <div className="plan-settings__cta-wrapper">
            <Switch
              id="subscriptionSwitch"
              onChange={setIsYearly}
              ariaLabelledby="subscriptionLabel"
              defaultChecked={switchInitialValue(userService)}
            />
            {userService?.data?.CUSTOMER_ID && (
              <ButtonNew
                className="plan-settings__cta"
                type="text"
                disabled={isLoading}
                loading={isLoading}
                onClick={async () => handleAsyncAction(PaymentsService.createPortalSession)}
                iconStart={<CreditCard />}
              >
                Edit payment data
              </ButtonNew>
            )}
          </div>
        </header>
        <div className="plan-settings__plans">
          {planData[isYearly ? "yearly" : "monthly"].map((plan) => (
            <Plan key={plan.title} {...plan} />
          ))}
        </div>
      </div>
    </>
  );
};
