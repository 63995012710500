import { useEffect, useState } from "react";
import { Trait } from "grapesjs";
import { projectsService } from "../../../../../../machines/projects/projects.instance.ts";
import { pagesService } from "../../pages/pages.service.ts";

type RedirectType = "internal" | "external";

export const Redirect = ({ trait }: { trait: Trait }) => {
  const [pages, subscribePagesService] = useState(pagesService.state);
  const [redirectType, setRedirectType] = useState<RedirectType>("internal");
  const [value, setValue] = useState(trait.getValue());
  const activeProject = projectsService.getActiveProject();
  const routing = activeProject?.pages
    ? Object.values(activeProject.pages).map((page) => ({ slug: page.slug, name: page.name, frameId: page.frameId }))
    : [];

  useEffect(() => {
    pagesService.subscribe(subscribePagesService);
    // Initialize the redirect type based on the value
    if (value.startsWith("https://")) {
      setRedirectType("external");
    } else if (value.startsWith("/")) {
      setRedirectType("internal");
    } else {
      // Default to internal if value doesn't match known patterns
      setRedirectType("internal");
    }

    return () => {
      pagesService.unsubscribe(subscribePagesService);
    };
  }, []);

  useEffect(() => {
    // Update the trait value based on the selected redirect type and value
    if (trait.getValue() !== value) {
      trait.setValue(value);
    }
  }, [value]);

  return (
    <li key={trait.cid} className="trait-manager__trait">
      <label>Redirect when click</label>

      {/* Redirect Type Selection */}
      <div className="redirect-type">
        <label>
          <input
            type="radio"
            name="redirectType"
            value="internal"
            checked={redirectType === "internal"}
            onChange={() => setRedirectType("internal")}
          />
          Internal Redirect (from the list of pages)
        </label>
        <label>
          <input
            type="radio"
            name="redirectType"
            value="external"
            checked={redirectType === "external"}
            onChange={() => setRedirectType("external")}
          />
          External Redirect (use a full HTTPS URL)
        </label>
      </div>

      {/* Conditional Rendering of Input based on Redirect Type */}
      {redirectType === "internal" ? (
        <select onChange={(e) => setValue(e.target.value)} value={value}>
          <option value="">Select a page</option>
          {routing.map((route) => (
            <option key={route.slug} value={"pageId" + "#" + route.frameId}>
              {route.name}
            </option>
          ))}
        </select>
      ) : (
        <input
          type="url"
          placeholder="https://example.com"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          pattern="https://.*"
          required
        />
      )}

      {/* Helper Text */}
      <p>
        {redirectType === "internal"
          ? "Choose a page from the list for internal redirects."
          : "Enter a full HTTPS URL for external redirects."}
      </p>
    </li>
  );
};
