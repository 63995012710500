import { useEffect, useState } from "react";
import { traitMachineInstance } from "./index.ts";
import { Trait } from "grapesjs";

import "./TraitManager.css";
import { TypeManager } from "./Inputs/TypeManager.tsx";

export type UseStateMachine<M, S> = (machine: M) => S;
export const useStateMachine: UseStateMachine<any, any> = (machineInstance) => {
  const [state, setState] = useState(machineInstance.initialState.context.service.state);

  useEffect(() => {
    machineInstance.start();
    machineInstance.initialState.context.service.subscribeInUseEffect((st) => setState(st));
    return () => {
      machineInstance.stop();
      machineInstance.initialState.context.service.unsubscribe(setState);
    };
  }, []);

  return { service: machineInstance.initialState.context.service, state };
};

export const TraitManager = () => {
  const { state } = useStateMachine(traitMachineInstance);

  return (
    <div className="trait-manager custom-scrollbar">
      <h2 className="trait-manager__header">Trait Manager</h2>
      <ul className="trait-manager__traits">
        {state.traits.map((trait: Trait) => {
          return <TypeManager key={trait.cid} trait={trait} />;
        })}
      </ul>
    </div>
  );
};
