import { Text } from "./Text.tsx";
import { Trait } from "grapesjs";
import { Select } from "./Select.tsx";
import { Checkbox } from "./Checkbox.tsx";
import { Redirect } from "./Redirect.tsx";
import { SelectOptionsTrait } from "./SelectOptionsTrait.tsx";

export const TypeManager = ({ trait }: { trait: Trait }) => {
  //Redirect is type of text but should be handled differently
  if (trait.getType() === "text" && trait.getLabel() === "data-redirect") {
    return <Redirect trait={trait} />;
  }

  if (trait.getType() === "text") {
    return <Text trait={trait} />;
  }

  if (trait.getType() === "select") {
    return <Select trait={trait} />;
  }

  if (trait.getType() === "checkbox") {
    return <Checkbox trait={trait} />;
  }

  if (trait.getType() === "select-options") {
    return <SelectOptionsTrait trait={trait} />;
  }

  return "Type not supported" + trait.getType();
};
