import { useEffect, useState } from "react";
import { pageActionsServiceInstance } from "./PageActionsModal.service";
import { pagesInstance } from "../../../pages/Alpha_O/modules/pages/pages.instance";

import { Button } from "../../atoms/Button/Button";
import { Duplicate } from "../../../../assets/svg/Duplicate";
import { Rename } from "../../../../assets/svg/Rename";
import { Settings } from "../../../../assets/svg/Settings";
import { Trash2 } from "../../../../assets/svg/Trash2";

import useOutsideClick from "../../../../hooks/useOutsideClick/useOutsideClick";
import { usePositionInScreen } from "../../../../hooks/usePositionInScreen/usePositionInScreen";

import { TPageActionsModal } from "./PageActionsModal.types";

import "./PageActionsModal.css";

export const PageActionsModal: TPageActionsModal = () => {
  const [pageActionsService, subscribePageActionsService] = useState(pageActionsServiceInstance.state);
  const [isPositionCalculated, setIsPositionCalculated] = useState(false);
  const { isOpen, setIsRenameInputVisible, page, position } = pageActionsService;
  const ref = useOutsideClick<HTMLDivElement>(() => {
    pageActionsServiceInstance.closeModal();
    setIsPositionCalculated(false);
  });

  const { coordinates } = usePositionInScreen(ref, position, setIsPositionCalculated, 32);

  useEffect(() => {
    pageActionsServiceInstance.subscribe(subscribePageActionsService);
    return () => pageActionsServiceInstance.unsubscribe(subscribePageActionsService);
  }, []);

  if (!isOpen) return null;

  return (
    <div
      className={`pages-menu__actions-modal`}
      style={{
        ...coordinates,
        opacity: isPositionCalculated ? 1 : 0,
      }}
      ref={ref}
    >
      {/* <Button type="ternary" iconStart={<Settings />}>
        Settings
      </Button> */}
      <Button
        type="ternary"
        iconStart={<Rename />}
        onClick={() => {
          pageActionsServiceInstance.closeModal();
          setIsRenameInputVisible && setIsRenameInputVisible(true);
        }}
      >
        Rename
      </Button>
      {/* <Button type="ternary" iconStart={<Duplicate />}>
        Duplicate
      </Button> */}
      <Button
        type="ternary"
        onClick={() => {
          pagesInstance.send("DELETE_PAGE", { id: page?.getId() });
          pageActionsServiceInstance.closeModal();
        }}
        iconStart={<Trash2 />}
      >
        Delete
      </Button>
    </div>
  );
};
