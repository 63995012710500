import { modalMachineInstance } from "../../index.ts";
import { AlertTriangle } from "../../../../../../../../assets/svg/alert-triangle.tsx";
import { dashboardInstance } from "../../../../../../../machines/dashboard/dashboard.instance.ts";

import "./UpgradeModal.css";

export const UpgradeModal = () => {
  return (
    <div className="upgrade-modal">
      <div className="upgrade-modal__info-wrapper">
        <div className="upgrade-modal__svg-wrapper">
          <AlertTriangle />
        </div>
        <h2>Upgrade Plan</h2>
        <p>If u want add more pages, please upgrade your plan. You can do it in the dashboard.</p>
      </div>
      <div className="upgrade-modal__cta-wrapper">
        <button
          className="upgrade-modal__cta upgrade-modal__cta--cancel"
          onClick={() => {
            modalMachineInstance.send("CLOSE");
          }}
        >
          Cancel
        </button>
        <button
          className="upgrade-modal__cta upgrade-modal__cta--upgrade"
          onClick={() => {
            modalMachineInstance.send("CLOSE");
            dashboardInstance.send("PROJECT_SETTINGS_PLANS");
          }}
        >
          Upgrade plan
        </button>
      </div>
    </div>
  );
};
