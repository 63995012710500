import { useState } from "react";
import { editorInstance, editorService } from "../editor";

import { Button } from "../../../../AlphaO/atoms/Button";
import { Close } from "../../../../../assets/svg/Close";
import { ChevronDown } from "../../../../../assets/svg/ChevronDown";

import "./BlocksMenu.css";

const ElementsBlock = ({ label, category }: any) => {
  const [isUnfolded, setIsUnfolded] = useState(true);
  return (
    <>
      <h2
        className={`blocks-menu__heading${isUnfolded ? " active" : ""}`}
        onClick={() => setIsUnfolded((prev) => !prev)}
      >
        {label}
        <ChevronDown />
      </h2>
      {isUnfolded && (
        <ul className="blocks-menu__blocks-list">
          {editorService.blocks.map((block) => {
            if (block.category === category) {
              return (
                <li
                  draggable
                  key={block.id}
                  onDragEnd={() => editorInstance.send("DRAG_END", { id: block.id })}
                  onDragStart={() => editorInstance.send("DRAG_START", { id: block.id })}
                >
                  <div className="blocks-menu__block-element">
                    <figure
                      className="blocks-menu__svg-wrapper"
                      dangerouslySetInnerHTML={{ __html: block.media || "" }}
                    ></figure>
                    <span className="truncate-text">{block.label}</span>
                  </div>
                </li>
              );
            }
          })}
        </ul>
      )}
    </>
  );
};

export const BlocksMenu = () => {
  return (
    <div className="blocks-menu" id={"gjs-blocks"}>
      <h2 className="blocks-menu__heading blocks-menu__heading--main">
        Elements
        <Button
          type="icon"
          onClick={() => editorInstance.send("CLOSE_ADD_NEW_ELEMENT_PANEL")}
          aria-label="Close blocks menu"
          iconStart={<Close />}
        ></Button>
      </h2>
      <div className="blocks-menu__tabpanel">
        <ElementsBlock label="Basic" category="Layout" />
        <ElementsBlock label="Form" category="Forms" />
        <ElementsBlock label="Extra" category="Extra" />
      </div>
    </div>
  );
};
