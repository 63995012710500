import { useState, useEffect } from "react";

import { authInstance } from "../../../../machines/auth/auth.instance";
import { UserService } from "../../../../services/UserService/UserService";

import { Avatar } from "../Avatar";

import useOutsideClick from "../../../../hooks/useOutsideClick/useOutsideClick";

import { ChevronDown } from "../../../../../assets/svg/chevron_down";
import { Logout } from "../../../../../assets/svg/logout_new";

import type { TDropdown, DropdownOption } from "./Dropdown.types";

import "./Dropdown.css";

const userService = UserService.getInstance();

const options: DropdownOption[] = [
  // {
  //   title: "Pricing & Billing",
  //   onClick: () => dashboardInstance.send("PLAN_BILLING"),
  // },
  {
    title: "Logout",
    onClick: () => authInstance.send("LOGOUT"),
    icon: <Logout />,
  },
];

export const Dropdown: TDropdown = ({ label, avatarSrc }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [_, setState] = useState(userService.state);

  const ref = useOutsideClick<HTMLDivElement>(() => setIsOpen(false));

  const handleClick = () => setIsOpen((prev) => !prev);

  useEffect(() => {
    userService.subscribe(setState);
    return () => userService.unsubscribe(setState);
  }, []);

  return (
    <div className="dropdown" ref={ref}>
      <button
        className="dropdown__button"
        type="button"
        role="combobox"
        onClick={handleClick}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        aria-controls="dropdown__drawer"
      >
        <Avatar className="dropdown__avatar" src={avatarSrc} />
        <span className="dropdown__username dropdown__typography">{label}</span>
        <span className={`dropdown__arrow ${isOpen && "dropdown__arrow--up"}`}>
          <ChevronDown />
        </span>
      </button>
      <div className={`dropdown__drawer ${isOpen && "dropdown__drawer--visible"}`}>
        <div className="dropdown__drawer-heading">
          <span className="dropdown__typography">{userService.data?.EMAIL}</span>
        </div>
        <div className="dropdown__drawer-items">
          {options.map(({ title, onClick, icon }) => {
            return (
              <button key={title} className="dropdown__drawer-item" onClick={onClick} type="button">
                <span className="dropdown__typography">{title}</span>
                {icon && icon}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};
