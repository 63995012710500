import { useState, useEffect } from "react";

export const usePositionInScreen = (
  ref: React.RefObject<HTMLDivElement>,
  position: { x: number; y: number },
  setIsPositionCalculated: (state: boolean) => void,
  offset: number = 0
) => {
  const [coordinates, setCoordinates] = useState<any>({ top: 0, left: 0 });

  useEffect(() => {
    if (ref.current) {
      const refPosition = ref.current.getBoundingClientRect();
      const { innerHeight } = window;

      const isBelowWindow = position.y + refPosition.height > innerHeight;

      setCoordinates({
        left: position.x + "px",
        top: isBelowWindow ? position.y - refPosition.height + offset + "px" : position.y + "px",
      });

      setIsPositionCalculated(true);
    }
  }, [position.y, ref.current]);

  return { coordinates };
};
