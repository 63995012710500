import { type CSSProperties } from "react";

type TTrash = {
  className?: string;
  style?: CSSProperties;
};

export const Trash = ({ className, style }: TTrash) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M16.6667 8.00033L15.0042 17.4556C14.8641 18.2525 14.1718 18.8337 13.3627 18.8337H6.63728C5.82816 18.8337 5.13592 18.2525 4.9958 17.4556L3.33333 8.00033M17.5 5.50033H12.8125M12.8125 5.50033V3.83366C12.8125 2.91318 12.0663 2.16699 11.1458 2.16699H8.85417C7.93369 2.16699 7.1875 2.91318 7.1875 3.83366V5.50033M12.8125 5.50033H7.1875M2.5 5.50033H7.1875"
      stroke="#727272"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
