import { useState } from "react";
import { Trash } from "../../../../assets/svg/Trash";

import { TSelectOption } from "./SelectOption.types";
import { Button } from "../../atoms/Button";

export const SelectOption: TSelectOption = ({ model, editOptionValue, editOptionLabel, removeOption }) => {
  const [value, setValue] = useState(model.get("attributes")?.value || "");
  const [label, setLabel] = useState(model.content);

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (value === e.target.value) return;

    setValue(e.target.value);
    editOptionValue(model, e.target.value);
  };

  const handleChangeLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (label === e.target.value) return;

    setLabel(e.target.value);
    editOptionLabel(model, e.target.value);
  };

  return (
    <li className="trait-manager__options-list-element">
      <label htmlFor={`option-value-${model.cid}`}>Value</label>
      <input id={`option-value-${model.cid}`} type="text" value={value} onChange={handleChangeValue} />
      <label htmlFor={`option-label-${model.cid}`}>Label</label>
      <input id={`option-label-${model.cid}`} type="text" value={label} onChange={handleChangeLabel} />
      <Button
        type="primary"
        iconStart={<Trash />}
        onClick={() => {
          model.remove();
          removeOption(model);
        }}
      >
        Remove option
      </Button>
    </li>
  );
};
