import { useEffect } from "react";

import "./UploadAsset.css";
import { assetManagerInstance } from "../../../asset-manager";
import { MyDropzone } from "./DropZone.tsx";

export const UploadAsset = () => {
  useEffect(() => {}, []);

  return (
    <div className="unpublish-modal">
      <div className="unpublish-modal__cta-wrapper">
        <button
          className="unpublish-modal__cta unpublish-modal__cta--cancel"
          onClick={() => assetManagerInstance.send("CLOSE_MODAL")}
        >
          Cancel
        </button>
        <MyDropzone />
      </div>
    </div>
  );
};
