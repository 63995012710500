import { type CSSProperties } from "react";

type TEssentialPlan = {
  className?: string;
  style?: CSSProperties;
};

export const EssentialPlan = ({ className, style }: TEssentialPlan) => (
  <svg
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <g id="Frame 1000006085">
      <g id="Black and White Collection 12">
        <path
          id="Vector"
          d="M47.8787 38.2481C47.4939 37.6356 46.9908 37.1058 46.3988 36.6899C45.8069 36.274 45.138 35.9803 44.4313 35.8258C41.7272 35.197 39.3821 33.5233 37.9082 31.1706C36.4344 28.8179 35.9517 25.9774 36.5657 23.27C36.8017 22.2235 36.7233 21.1307 36.3403 20.1286C35.9573 19.1266 35.2867 18.26 34.4129 17.6379C33.5389 17.0157 32.5007 16.6657 31.4284 16.6316C30.3562 16.5976 29.2979 16.8812 28.3863 17.4466C27.4747 18.0122 26.7506 18.8344 26.3048 19.8101C25.859 20.7859 25.7114 21.8716 25.8807 22.9309C26.0499 23.9902 26.5283 24.9759 27.256 25.7642C27.9835 26.5524 28.9278 27.1082 29.9702 27.3615C31.3188 27.6657 32.594 28.233 33.7228 29.031C34.8517 29.829 35.8119 30.842 36.5485 32.0117C37.2852 33.1816 37.7836 34.4853 38.0154 35.8481C38.2473 37.2109 38.2078 38.6061 37.8994 39.9537C37.7167 40.8224 37.7493 41.7226 37.9942 42.5758C38.2392 43.429 38.6892 44.2094 39.3049 44.8489C39.9205 45.4884 40.6832 45.9676 41.5265 46.2448C42.3699 46.522 43.2681 46.5886 44.1431 46.4391C45.0182 46.2896 45.8433 45.9283 46.5467 45.3868C47.2502 44.8452 47.8104 44.14 48.1788 43.3323C48.5471 42.5246 48.7123 41.6391 48.66 40.753C48.6077 39.8668 48.3395 39.0068 47.8787 38.2481Z"
          fill="#FF4900"
        />
        <path
          id="Vector_2"
          d="M14.4099 32.2064C15.3621 32.7261 16.4478 32.9494 17.5278 32.8476C18.6079 32.7459 19.6328 32.3238 20.4712 31.6355C21.3097 30.9472 21.9234 30.0241 22.2335 28.9846C22.5437 27.9451 22.5361 26.8367 22.2119 25.8015C21.8009 24.4814 21.6545 23.0934 21.7811 21.7167C21.9077 20.34 22.3047 19.0018 22.9495 17.7789C23.5943 16.556 24.474 15.4724 25.5384 14.5901C26.6028 13.7078 27.8308 13.0444 29.1521 12.6377C30.1769 12.3168 31.0838 11.6998 31.7587 10.8646C32.4337 10.0294 32.8465 9.01322 32.9452 7.94391C33.044 6.87459 32.8243 5.79995 32.3138 4.85529C31.8032 3.91056 31.0246 3.13796 30.076 2.63473C29.1273 2.13151 28.0511 1.92006 26.9826 2.02712C25.9141 2.13411 24.9011 2.55474 24.0711 3.23615C23.2411 3.9175 22.6313 4.82916 22.3182 5.85634C22.0051 6.88353 22.0028 7.98037 22.3117 9.00882C23.1224 11.6639 22.8482 14.5322 21.5493 16.9857C20.2503 19.4391 18.0322 21.278 15.3806 22.1C14.3338 22.4035 13.4022 23.0143 12.7065 23.8533C12.0107 24.6923 11.5828 25.7208 11.4782 26.8057C11.3737 27.8906 11.5972 28.9819 12.1199 29.9383C12.6426 30.8947 13.4403 31.6722 14.4099 32.1701V32.2064Z"
          fill="#FF4900"
        />
        <path
          id="Vector_3"
          d="M28.4173 31.3162C27.6935 31.3416 26.9822 31.5112 26.3248 31.815C25.6674 32.1187 25.0772 32.5506 24.5888 33.0853C22.6939 35.112 20.0737 36.3061 17.301 36.4064C14.5282 36.5067 11.8285 35.5051 9.79211 33.6206C9.26595 33.1269 8.64693 32.7427 7.97099 32.4904C7.29511 32.2381 6.57577 32.1227 5.85482 32.1509C4.96743 32.1927 4.1037 32.4512 3.33916 32.9036C2.57455 33.356 1.9324 33.9887 1.46864 34.7464C1.00481 35.5041 0.733567 36.3639 0.678438 37.2505C0.623376 38.1373 0.786097 39.0239 1.15253 39.8333C1.51897 40.6425 2.07786 41.3499 2.78054 41.8935C3.48321 42.4371 4.30821 42.8004 5.18361 42.9518C6.05901 43.1032 6.9582 43.0381 7.8026 42.762C8.64707 42.486 9.41107 42.0075 10.028 41.3682C10.9664 40.3522 12.0961 39.5312 13.3522 38.9525C14.6085 38.3737 15.9665 38.0484 17.3486 37.9953C18.7307 37.9423 20.1097 38.1623 21.4066 38.643C22.7035 39.1238 23.8928 39.8556 24.9064 40.7967C25.5662 41.3929 26.3622 41.8184 27.2245 42.0358C28.0869 42.2533 28.9894 42.2562 29.8531 42.0442C30.7168 41.8322 31.5154 41.4119 32.1791 40.8198C32.8427 40.2278 33.3512 39.4822 33.6601 38.6482C33.969 37.8142 34.0688 36.9172 33.9508 36.0357C33.8329 35.1542 33.5007 34.3151 32.9833 33.5916C32.466 32.8682 31.7794 32.2826 30.9834 31.8859C30.1874 31.4893 29.3063 31.2936 28.4173 31.3162Z"
          fill="#FF4900"
        />
      </g>
    </g>
  </svg>
);
