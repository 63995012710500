import { useEffect, useState } from "react";
import { Component, Trait } from "grapesjs";
import { debounce } from "lodash";

import { SelectOption } from "../../../../../AlphaO/molecules/SelectOption/SelectOption.tsx";
import { Plus } from "../../../../../../../assets/svg/plus.tsx";
import { Button } from "../../../../../AlphaO/atoms/Button/Button.tsx";

export const SelectOptionsTrait = ({ trait }: { trait: Trait }) => {
  const [options, setOptions] = useState<Component[]>([]);
  const components = trait.target;

  useEffect(() => {
    const options = components.get("components")?.models || [];

    setOptions(options);
  }, []);

  const addOption = () => {
    const select = components.get("components");

    if (!select) return;

    // if props not in array return object and show type error => any[] return type in grpaejs
    const newComponent = select.add([
      {
        type: "option",
        tagName: "option",
        content: "New option",
        attributes: {
          value: "new-option",
        },
      },
    ]);

    if (newComponent) {
      setOptions([...options, ...newComponent]);
    }
  };

  const editOptionValue = (option: Component, value: string) => {
    option.setAttributes({ value: value });

    const changedOptions = components.get("components")?.models || [];
    setOptions([...changedOptions]);
  };

  const debouncedEditOptionValue = debounce(editOptionValue, 500);

  const editOptionLabel = (option: Component, label: string) => {
    option.set("content", label);

    const changedOptions = components.get("components")?.models || [];
    setOptions([...changedOptions]);
  };

  const debouncedEditOptionLabel = debounce(editOptionLabel, 500);

  const removeOption = (model: Component) => {
    setOptions(options.filter((option) => option.cid !== model.cid));
  };

  return (
    <li className="trait-manager__trait trait-manager__trait--option">
      <h3>
        Options
        <Button
          type="primary"
          onClick={() => {
            addOption();
          }}
          iconStart={<Plus />}
        >
          Add option
        </Button>
      </h3>

      <ul className="trait-manager__options-list">
        {components.get("components")?.models.map((model) => {
          return (
            <SelectOption
              key={model.cid}
              model={model}
              editOptionValue={debouncedEditOptionValue}
              editOptionLabel={debouncedEditOptionLabel}
              removeOption={removeOption}
            />
          );
        })}
      </ul>
    </li>
  );
};
