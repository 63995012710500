import { useEffect, useState } from "react";
import { dashboardInstance } from "../../../machines/dashboard/dashboard.instance";
import { Seo } from "../../atoms/Seo";
import { Navigation } from "../../Dashboard/organisms/Navigation";
import { ProjectSettingsNavigation } from "../../molecules/ProjectSettingsNavigation";
import { GeneralSettings } from "../../molecules/GeneralSettings";
import { PlanSettings } from "../../molecules/PlanSettings/PlanSettings";

import type { NavigationTabs, TProjectSettings } from "./ProjectSettings.types";

import "./ProjectSettings.css";

export const ProjectSettings: TProjectSettings = () => {
  const [activeTab, setActiveTab] = useState<NavigationTabs>("GENERAL");

  useEffect(() => {
    dashboardInstance.onTransition((state) => {
      const projectSettingsState = state.value.DASHBOARD?.PROJECT_SETTINGS;
      if (projectSettingsState) {
        setActiveTab(projectSettingsState);
      }
    });
  }, []);

  const changeState = (tab: NavigationTabs) => {
    dashboardInstance.send(tab);
  };

  return (
    <>
      <Seo title="Project Settings - Codejet" />
      <Navigation />
      <div className="project-settings__content">
        <ProjectSettingsNavigation activeTab={activeTab} setActiveTab={changeState} />
        <div>
          {activeTab === "GENERAL" && <GeneralSettings />}
          {activeTab === "PLANS" && <PlanSettings />}
        </div>
      </div>
    </>
  );
};
