const codejetComponents = [
  ".codejet_accordion",
  ".codejet_alert",
  ".codejet_button",
  ".codejet_checkbox",
  ".codejet_chips",
  ".codejet_input",
  ".codejet_pagination_button",
  ".codejet_pagination_separator",
  ".codejet_pagination",
  ".codejet_radio",
  ".codejet_switch",
  ".codejet_tags",
  ".codejet_textfield",
];

export function extractComponents(css: string) {
  // console.log("css", css);
  let components = "";
  let styles = css;

  // Helper function to extract and remove blocks matching the regex
  function extractBlocks(regex: RegExp) {
    let match;
    while ((match = regex.exec(styles)) !== null) {
      components += match[0] + "\n";
      styles = styles.replace(match[0], "");
      regex.lastIndex = 0; // Reset regex index to handle overlapping matches
    }
  }

  // Regex to match :root blocks with any selectors following it
  const rootRegex = /:root\s*[^}]*{[^}]*}/g;
  extractBlocks(rootRegex);

  // Create and apply regex for each class in classList
  codejetComponents?.forEach((cls) => {
    const classRegex = new RegExp(`\\${cls}[^{]*\{[^}]*\}`, "g");
    extractBlocks(classRegex);
  });

  return {
    styles: styles,
    components: components,
  };
}
