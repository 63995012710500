import { type CSSProperties } from "react";

type TAddComponent = {
  className?: string;
  style?: CSSProperties;
};

export const AddComponent = ({ className, style }: TAddComponent) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      style={style}
    >
      <path
        d="M5.5 8.5L9 12L5.5 15.5L2 12L5.5 8.5Z"
        fill="#71717A"
        stroke="#71717A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 2L15.5 5.5L12 9L8.5 5.5L12 2Z"
        fill="#71717A"
        stroke="#71717A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 8.5L22 12L18.5 15.5L15 12L18.5 8.5Z"
        fill="#71717A"
        stroke="#71717A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15L15.5 18.5L12 22L8.5 18.5L12 15Z"
        fill="#71717A"
        stroke="#71717A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
