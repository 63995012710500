import { useState, useEffect } from "react";

import { dashboardInstance } from "../../../../machines/dashboard/dashboard.instance";
import { editorInstance, editorService } from "../../../pages/Alpha_O/modules/editor";
import { projectsInstance } from "../../../../machines/projects/projects.instance";
import { pagesService } from "../../../pages/Alpha_O/modules/pages/pages.service";
import { modalMachineInstance } from "../../../pages/Alpha_O/modules/modal";

import { Button } from "../../atoms/Button";

import { Code } from "../../../../assets/svg/Code";
import { CodejetRocket } from "../../../../assets/svg/CodejetRocket";
import { Dashboard } from "../../../../assets/svg/Dashboard";
import { EyeSolid } from "../../../../assets/svg/EyeSolid";
import { FaceID } from "../../../../assets/svg/FaceID";
import { Page } from "../../../../assets/svg/Page";
import { Redo } from "../../../../assets/svg/Redo";
import { Settings } from "../../../../assets/svg/Settings";
import { SizeLaptop } from "../../../../assets/svg/SizeLaptop";
import { Undo } from "../../../../assets/svg/Undo";
import { UserScan } from "../../../../assets/svg/UserScan";

import { TTopBar } from "./TopBar.types";

import "./TopBar.css";
import { SizeTablet } from "../../../../assets/svg/SizeTablet";
import { SizeMobile } from "../../../../assets/svg/SizeMobile";

export const TopBar: TTopBar = ({ togglePreview }) => {
  const [_, updatePageManagerState] = useState(pagesService.state);
  const editor = editorService.getEditor();
  const project = projectsInstance.getSnapshot().context.service;
  const activeProject = project.getActiveProject();
  const activeProjectId = activeProject?.projectId;

  const currentPage = editor?.Pages.getSelected()?.getName() || "";

  useEffect(() => {
    pagesService.subscribe((state) => updatePageManagerState(state));
    return () => {
      pagesService.unsubscribe(updatePageManagerState);
    };
  }, []);

  const handleDeviceChange = (e) => {
    const deviceType = e.target.value;
    editorInstance.send("CHANGE_DEVICE_TYPE", { deviceType });
  };

  return (
    <div className="top-bar">
      <div className="top-bar__left-column">
        <button key="save" onClick={() => {}} title="Codejet Logo">
          <CodejetRocket />
        </button>
      </div>
      <div className="top-bar__right-column">
        <div style={{ display: "flex", gap: "1rem" }}>
          <button key="dashboard" onClick={() => dashboardInstance.send("PROJECTS")} title="Go to dashboard">
            <Dashboard />
            Dashboard
          </button>
          <Button type="primary" truncate iconStart={<Page />} className="top-bar__current-page">
            {currentPage}
          </Button>
        </div>

        <div className="top-bar__device-pick">
          <input
            id="device-desktop"
            type="radio"
            name="device"
            value="Desktop"
            onChange={handleDeviceChange}
            checked={editor?.getDevice() === "Desktop"}
            className="vs-hidden"
          />
          <label htmlFor="device-desktop">
            <SizeLaptop />
          </label>
          <input
            id="device-tablet"
            type="radio"
            name="device"
            value="Tablet"
            onChange={handleDeviceChange}
            checked={editor?.getDevice() === "Tablet"}
            className="vs-hidden"
          />
          <label htmlFor="device-tablet">
            <SizeTablet />
          </label>
          <input
            id="device-mobile"
            type="radio"
            name="device"
            value="Mobile"
            onChange={handleDeviceChange}
            checked={editor?.getDevice() === "Mobile"}
            className="vs-hidden"
          />
          <label htmlFor="device-mobile">
            <SizeMobile />
          </label>
        </div>

        <div className="top-bar__cta-wrapper">
          <button
            className={`button-icon${editor?.Commands.isActive("sw-visibility") ? " active" : ""}`}
            key="component_outline"
            onClick={() => {
              editorInstance.send("TOGGLE_OUTLINE");
            }}
            title="Component outline"
          >
            <FaceID />
          </button>
          <button className="button-icon" key="Preview" onClick={togglePreview} title="Preview">
            <EyeSolid />
          </button>
          <button
            className={`button-icon${editor?.Commands.isActive("fullscreen") ? " active" : ""}`}
            // className={`button-icon${document.fullscreenElement !== null ? " active" : ""}`}
            key="fullscreen"
            onClick={() => {
              editorInstance.send("TOGGLE_FULLSCREEN");
            }}
            title="Fullscreen"
          >
            <UserScan />
          </button>
          <button
            className="button-icon"
            key="undo"
            disabled={!editor?.UndoManager.hasUndo()}
            onClick={() => {
              editorInstance.send("UNDO");
            }}
            title="Undo"
          >
            <Undo />
          </button>
          <button
            className="button-icon"
            key="redo"
            disabled={!editor?.UndoManager.hasRedo()}
            onClick={() => {
              editorInstance.send("REDO");
            }}
            title="Redo"
          >
            <Redo />
          </button>
          <button
            className="button-icon"
            key="Settings"
            onClick={() => dashboardInstance.send("PROJECT_SETTINGS", { projectId: activeProjectId })}
            title="Settings"
          >
            <Settings />
          </button>
          <button
            key="view_code"
            className="button--primary"
            onClick={() => {
              modalMachineInstance.send("OPEN", { modalId: "viewCode" });
            }}
            title="View Code"
          >
            <Code /> Code
          </button>
          <button
            key="publish_project"
            className="button--primary"
            onClick={() => {
              modalMachineInstance.send("OPEN", { modalId: "domains" });
            }}
            title="Publish"
          >
            <CodejetRocket /> Publish
          </button>
        </div>
      </div>
    </div>
  );
};
