import { ButtonNew } from "../../../atoms/ButtonNew";

import { TPlan } from "./Plan.types";

import "./Plan.css";

export const Plan: TPlan = ({
  title,
  icon,
  price,
  frequency,
  description,
  publishingOn,
  featuresTitle,
  features,
  extensionsTitle,
  extensions,
  closeDate,
  startDate,
  actionButtonLabel,
  actionButtonOnClick,
  actionButtonHref,
  actionCurrentPlan,
  actionButtonLoading,
}) => {
  return (
    <article className="plan" data-priceplan-type={title.toLocaleLowerCase()}>
      <header className="plan__header">
        {icon ? icon : null}
        <div className="plan__title-wrapper">
          <h3 className="plan__title">{title}</h3>
          <p className="plan__description">{description}</p>
        </div>
        <div className="plan__price-container">
          <span className="plan__price">{price}</span>
          {frequency && <span className="plan__frequency">{frequency}</span>}
        </div>
      </header>

      <div className="plan__actions">
        <ButtonNew
          className="plan__action-button"
          disabled={actionCurrentPlan || startDate !== ""}
          orange={!actionButtonLabel.includes("Downgrade") && !actionCurrentPlan}
          onClick={actionButtonOnClick}
          href={actionButtonHref}
          loading={actionButtonLoading}
        >
          {startDate ? (
            ` (will begin on ${startDate})`
          ) : (
            <>
              {actionButtonLabel}
              {closeDate ? ` (until ${closeDate})` : ""}
            </>
          )}
        </ButtonNew>
      </div>

      <p className="plan__feature">{publishingOn}</p>

      <section className="plan__features">
        <h4 className="plan__features-title">{featuresTitle}</h4>
        <ol className="plan__features-list">
          {features.map((feature) => (
            <li key={feature} className="plan__feature">
              {feature}
            </li>
          ))}
        </ol>
      </section>

      <section className="plan__features">
        <h4 className="plan__features-title">{extensionsTitle}</h4>
        <ol className="plan__features-list">
          {extensions.map((extension) => (
            <li key={extension} className="plan__feature">
              {extension}
            </li>
          ))}
        </ol>
      </section>
    </article>
  );
};
