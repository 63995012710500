import { projectsInstance } from "../../../../machines/projects/projects.instance";

import { NoContent } from "./NoContent";
import { ProjectCard } from "../../atoms/ProjectCard";

import { SubscriptionStatus, type Project } from "../../../../types/types";

export const RenderContent = ({
  isSkeleton,
  isNoContent,
  projects,
}: {
  isSkeleton: boolean;
  isNoContent: boolean;
  projects: Project[];
}) => {
  if (isSkeleton) {
    return (
      <>
        {Array.from({ length: 3 }).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <ProjectCard key={index} skeleton style={{ animationDelay: `calc(${index} * 250ms)` }} />
        ))}
      </>
    );
  } else if (isNoContent) {
    return <NoContent />;
  } else {
    const lockedStatuses = ["incomplete", "incomplete_expired", "past_due", "unpaid"];
    const essentialPlanPriceID = import.meta.env.VITE_ESSENTIAL_PRICE_ID;
    const proPlanPriceID = import.meta.env.VITE_PRO_PRICE_ID;
    const getPlan = (priceId: string) => {
      switch (priceId) {
        case "":
          return "free";
        case essentialPlanPriceID:
          return "essential";
        case proPlanPriceID:
          return "pro";
        default:
          return "free";
      }
    };
    return (
      <>
        {projects.map((project, idx) => {
          const { priceId = "", status = SubscriptionStatus.active } = project?.subscription || {};

          return (
            <ProjectCard
              key={project.projectId}
              name={project.name}
              description={project.description}
              timestamp={project.timestamp}
              thumbnail={project.thumbnail}
              isLocked={lockedStatuses.includes(status)}
              isPublished={project?.subdomain?.published || project?.customDomain?.published}
              onClick={() => projectsInstance.send("SELECT_PROJECT", { projectId: project.projectId })}
              handlePlanUpgrade={() =>
                projectsInstance.send("PROJECT_SETTINGS_PLANS", { projectId: project.projectId })
              }
              onEdit={() => projectsInstance.send("PROJECT_SETTINGS", { projectId: project.projectId })}
              onDelete={async () =>
                await projectsInstance.getSnapshot().context.service.deleteProject(project.projectId)
              }
              onDuplicate={async () =>
                await projectsInstance.getSnapshot().context.service.duplicateProject(project.projectId)
              }
              style={{ animationDelay: `calc(${idx} * 250ms)`, zIndex: `${996 - idx}` }}
              plan={getPlan(priceId)}
            />
          );
        })}
      </>
    );
  }
};
