import { forwardRef } from "react";

import { IconButton } from "../../../../../atoms/IconButton";
import { HamburgerSlim } from "../../../../../../../assets/svg/hamburger-slim";
import { ProjectCardActionsProps } from "./ProjectCardActions.types";

export const ProjectCardActions = forwardRef<HTMLDivElement, ProjectCardActionsProps>(
  (
    {
      isLoading,
      isMenuOpen,
      isDeleteModalOpen,
      isDuplicateModalOpen,
      plan,
      handleOpenMenu,
      handleOpenMenuKey,
      handleOnPlanUpgrade,
      handleOnEdit,
      handleOpenModal,
      handleOpenDuplicateModal,
    },
    ref
  ) => {
    return (
      <div
        className={"project-card__actions" + (isLoading || isMenuOpen ? " project-card__actions--visible" : "")}
        role="menu"
        tabIndex={0}
        ref={ref}
        onClick={handleOpenMenu}
        onKeyDown={handleOpenMenuKey}
      >
        <div className="project-card__dropdown-wrapper">
          <IconButton
            className={"project-card__btn" + (isMenuOpen ? " project-card__btn--menu-open" : "")}
            ariaLabel="project context menu"
          >
            <HamburgerSlim className={isLoading ? "loading-dots-animation" : ""} />
          </IconButton>
          <div className={"project-card__dropdown" + (isMenuOpen ? " project-card__dropdown--visible" : "")}>
            {plan !== "pro" ? (
              <button className="project-card__dropdown-item" type="button" onClick={handleOnPlanUpgrade}>
                Upgrade Plan
              </button>
            ) : null}

            <button className="project-card__dropdown-item" type="button" onClick={handleOnEdit}>
              Projects Settings
            </button>
            <button
              className={`project-card__dropdown-item${
                isDuplicateModalOpen ? " project-card__dropdown-item--hovered" : ""
              }`}
              type="button"
              onClick={handleOpenDuplicateModal}
            >
              Duplicate
            </button>
            <button
              className={
                "project-card__dropdown-item" + (isDeleteModalOpen ? " project-card__dropdown-item--hovered" : "")
              }
              type="button"
              onClick={handleOpenModal}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    );
  }
);
