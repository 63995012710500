import { useState, type ChangeEvent } from "react";
import { ButtonNew } from "../../../atoms/ButtonNew";

import { type TProjectSettingsTextArea } from "./ProjectSettingsTextArea.types";

import "./ProjectSettingsTextArea.css";

export const ProjectSettingsTextArea: TProjectSettingsTextArea = ({
  id,
  label,
  onChange,
  button = true,
  buttonLabel = "Clear",
  onClear,
  cols,
  rows = 4,
  placeholder = "",
  value,
  disabled = false,
  maxLength = undefined,
}) => {
  const [error, setError] = useState("");

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (maxLength && event.target.value.length >= maxLength) {
      setError(`Maximum length of ${maxLength} characters exceeded`);
      return;
    } else {
      setError("");
    }
    onChange(event);
  };

  const handleClear = () => {
    setError("");
    onClear && onClear();
  };

  return (
    <label htmlFor={id} className="project-settings__text-area-label">
      <div className="project-settings__text-area-controls">
        <span className="project-settings__label">{label}</span>
        {button && (
          <ButtonNew type="text" onClick={handleClear}>
            {buttonLabel}
          </ButtonNew>
        )}
      </div>
      <textarea
        className="project-settings__text-area"
        cols={cols}
        rows={rows}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        maxLength={maxLength}
      />
      {error && <div className="project-settings__text-area-error">{error}</div>}
    </label>
  );
};
