import { Policy } from "../../../../../types/types";

export class ActionProxy {
  policy: Policy = {
    projectId: "",
    maxPages: 0,
    plan: "",
    canPublishOnCustomDomain: false,
    version: "1.0.0",
  };

  loadPolicy = (policy: Policy) => {
    this.policy = policy;
  };

  canAddPage = (numberOfPages: number): boolean => {
    if (numberOfPages >= this.policy.maxPages) {
      return false;
    }
    return true;
  };

  canPublish = (numberOfPages: number): boolean => {
    return numberOfPages <= this.policy.maxPages;
  };

  canPublishOnCustomDomain = (numberOfPages: number): boolean => {
    return this.canPublish(numberOfPages) && this.policy.canPublishOnCustomDomain;
  };
}

export const actionProxyService = new ActionProxy();
