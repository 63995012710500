import { useEffect, useState } from "react";
import { assetManagerInstance, assetManagerService } from "./index.ts";
import { AssetManagerState } from "./asset-manager.service.ts";

import { Close } from "../../../../../assets/svg/Close.tsx";

import "./AssetManagerMenu.css";
import { Button } from "../../../../AlphaO/atoms/Button/Button.tsx";
import { Plus } from "../../../../../../assets/svg/plus.tsx";

export const AssetManagerMenu = () => {
  const [assetManagerState, updateAssetManagerState] = useState<AssetManagerState>(assetManagerService.state);

  useEffect(() => {
    assetManagerService.subscribe((state) => updateAssetManagerState(state));

    return () => {
      assetManagerService.unsubscribe(updateAssetManagerState);
    };
  }, []);

  if (!assetManagerState.open) return null;

  return (
    <div className="asset-manager">
      <h2 className="asset-manager__heading">
        My Assets
        <Button
          type="icon"
          onClick={() => assetManagerInstance.send("DESELECT_COMPONENT")}
          aria-label="Close asset manager"
          iconStart={<Close />}
        />
      </h2>
      <h2 className="asset-manager__heading">
        <Button
          type="icon"
          onClick={() => assetManagerInstance.send("UPLOAD")}
          aria-label="Close asset manager"
          iconStart={<Plus />}
        >
          Upload Media
        </Button>
      </h2>
      <div className="asset-manager__content">
        <ul className="asset-manager__list">
          {assetManagerState.assets.map((asset) => (
            <li key={asset.getSrc()}>
              <img
                src={asset.get("src")}
                alt={asset.get("name")}
                onClick={() => assetManagerInstance.send("SELECT_ASSET", { asset })}
              />
              {/* <button onClick={() => assetManagerService.remove(asset)}>Remove</button> */}
              {/* <button onClick={() => assetManagerInstance.send("REMOVE", { asset })}>Remove</button> */}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
