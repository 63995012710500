import { type CSSProperties } from "react";

type TStarter = {
  className?: string;
  style?: CSSProperties;
};

export const Starter = ({ className, style }: TStarter) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <g id="Frame 1000006085">
      <g id="Black and White Collection 0">
        <path id="Vector" d="M9.04297 48L24.0041 37.7071L38.9572 48L24.0041 0L9.04297 48Z" fill="#FF4900" />
        <path id="Vector_2" d="M16.6963 15.2754L0 21.4458L13.083 26.1845L16.6963 15.2754Z" fill="#FF4900" />
        <path id="Vector_3" d="M48.001 21.4458L31.3047 15.2754L34.918 26.1845L48.001 21.4458Z" fill="#FF4900" />
      </g>
    </g>
  </svg>
);
