import { type CSSProperties } from "react";

type TProPlan = {
  className?: string;
  style?: CSSProperties;
};

export const ProPlan = ({ className, style }: TProPlan) => (
  <svg
    width="44"
    height="48"
    viewBox="0 0 44 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <g id="Black and White Collection 2">
      <path
        id="Vector"
        d="M21.6339 32.679C20.3179 32.6849 19.0193 32.3661 17.8447 31.7488C16.6701 31.1315 15.6528 30.2334 14.8764 29.1281L9.46484 32.3615C9.67077 32.6085 9.91456 32.8182 10.1864 32.9822L19.8161 38.7562C20.379 39.0553 21.0019 39.2112 21.6339 39.2112C22.2658 39.2112 22.8888 39.0553 23.4516 38.7562L33.0813 32.9822C33.3532 32.8182 33.5969 32.6085 33.8029 32.3615L28.4052 29.0703C27.6334 30.1883 26.6166 31.0988 25.439 31.7264C24.2614 32.354 22.957 32.6806 21.6339 32.679Z"
        fill="#FF4900"
      />
      <path
        id="Vector_2"
        d="M13.1616 23.9656C13.1665 21.9127 13.9644 19.9336 15.4027 18.4064C16.841 16.8793 18.819 15.9112 20.9584 15.6873V9.46484C20.6338 9.5292 20.3215 9.64089 20.0326 9.79598L9.99373 15.3148C9.44809 15.663 8.99439 16.1269 8.66819 16.6703C8.34192 17.2137 8.15202 17.8216 8.11328 18.4467V29.4844C8.11774 29.8028 8.17129 30.119 8.27236 30.4226L13.9138 27.3183C13.4205 26.263 13.1643 25.121 13.1616 23.9656Z"
        fill="#FF4900"
      />
      <path
        id="Vector_3"
        d="M33.3719 15.311L23.8614 9.79577C23.5878 9.64075 23.2919 9.52914 22.9844 9.46484V15.6694C24.2764 15.8167 25.516 16.2671 26.6034 16.9845C27.6907 17.7019 28.5955 18.6662 29.2448 19.7996C29.8941 20.9331 30.2697 22.2042 30.3415 23.5105C30.4133 24.8167 30.1792 26.1217 29.6581 27.3203L35.0027 30.4226C35.0984 30.1192 35.1492 29.8033 35.1534 29.485V18.4546C35.1188 17.8276 34.9398 17.2172 34.6307 16.6717C34.3214 16.1261 33.8906 15.6603 33.3719 15.311Z"
        fill="#FF4900"
      />
      <path
        id="Vector_4"
        d="M40.3008 9.78328L24.6007 0.7033C23.68 0.24081 22.664 0 21.6338 0C20.6036 0 19.5878 0.24081 18.667 0.7033L2.96681 9.78328C2.10504 10.3455 1.38754 11.1027 0.872113 11.9938C0.356756 12.885 0.0580056 13.8849 0 14.9129V33.0728C0.0569239 34.1031 0.355133 35.1054 0.870491 35.999C1.38585 36.8926 2.10396 37.6523 2.96681 38.2167L18.667 47.2969C19.5878 47.7593 20.6036 48 21.6338 48C22.664 48 23.68 47.7593 24.6007 47.2969L40.3008 38.2167C41.1637 37.6523 41.8818 36.8926 42.3971 35.999C42.9125 35.1054 43.2107 34.1031 43.2676 33.0728V14.9129C43.2096 13.8849 42.9109 12.885 42.3955 11.9938C41.8801 11.1027 41.1626 10.3455 40.3008 9.78328ZM38.285 30.984C38.2389 31.7755 38.0082 32.545 37.6112 33.231C37.2144 33.9171 36.6624 34.5004 35.9995 34.9343L23.905 41.9254C23.1985 42.2826 22.4182 42.4687 21.6267 42.4687C20.8352 42.4687 20.0548 42.2826 19.3484 41.9254L7.26808 34.9343C6.60521 34.5004 6.05321 33.9171 5.65636 33.231C5.25945 32.545 5.02871 31.7755 4.9826 30.984V17.016C5.02871 16.2245 5.25945 15.455 5.65636 14.769C6.05321 14.0829 6.60521 13.4996 7.26808 13.0657L19.3341 6.07457C20.0406 5.71741 20.821 5.53129 21.6125 5.53129C22.404 5.53129 23.1844 5.71741 23.8909 6.07457L35.9853 13.0657C36.6508 13.498 37.2056 14.0807 37.605 14.7668C38.0044 15.4529 38.2373 16.2233 38.285 17.016V30.984Z"
        fill="#FF4900"
      />
      <path
        id="Vector_5"
        d="M15.5508 24.0004C15.5508 25.1369 15.9076 26.2479 16.5762 27.1929C17.2447 28.138 18.195 28.8745 19.3069 29.3095C20.4187 29.7444 21.642 29.8581 22.8224 29.6365C24.0026 29.4147 25.0868 28.8674 25.9376 28.0638C26.7886 27.2601 27.3681 26.2362 27.6029 25.1215C27.8376 24.0067 27.7172 22.8514 27.2566 21.8013C26.7961 20.7513 26.0163 19.8538 25.0157 19.2224C24.0151 18.5909 22.8387 18.2539 21.6353 18.2539C20.0216 18.2539 18.474 18.8593 17.3329 19.937C16.1918 21.0147 15.5508 22.4764 15.5508 24.0004Z"
        fill="#FF4900"
      />
    </g>
  </svg>
);
