import { AssetManagerService } from "./asset-manager.service.ts";
import { createMachine } from "xstate";
import { Asset, ComponentDefinition } from "grapesjs";
import { modalMachineInstance } from "../modal";

export const assetManagerMachine = (assetManagerService: AssetManagerService) => {
  return createMachine(
    {
      id: "assetManager",
      initial: "START",
      context: {
        service: assetManagerService,
      },

      states: {
        START: {
          on: {
            INIT: "INIT",
          },
        },
        INIT: {
          invoke: {
            src: "init",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        READY: {
          entry: ["notify"],
          on: {
            ADD: "ADD",
            REMOVE: "REMOVE",
            SELECT_ASSET: "SELECT_ASSET",
            CLOSE: "CLOSE",
            OPEN: "LOAD_ASSETS",
            SELECT_COMPONENT: "SELECT_COMPONENT",
            DESELECT_COMPONENT: "DESELECT_COMPONENT",
            CLEAR_ALL_ASSETS: "CLEAR_ALL_ASSETS",
            UPLOAD: "UPLOAD",
          },
        },

        UPLOAD: {
          initial: "OPEN_MODAL",
          states: {
            OPEN_MODAL: {
              entry: () => modalMachineInstance.send("OPEN", { modalId: "uploadAsset" }),
              on: {
                CLOSE_MODAL: "CLOSE_MODAL",
              },
            },
            CLOSE_MODAL: {
              entry: () => modalMachineInstance.send("CLOSE"),
              type: "final",
            },
            ERROR: {
              entry: () => {
                console.log("Error");
              },
            },
          },
          onDone: {
            target: "LOAD_ASSETS",
          },
        },

        SELECT_COMPONENT: {
          invoke: {
            src: "selectComponent",
            onDone: {
              target: "SHOULD_OPEN",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        SHOULD_OPEN: {
          // This is a guard condition
          always: [{ target: "LOAD_ASSETS", cond: "shouldOpen" }, { target: "READY" }],
        },

        DESELECT_COMPONENT: {
          after: {
            10: "CLOSE",
          },
          on: {
            SELECT_COMPONENT: "SELECT_COMPONENT",
          },
        },

        ADD: {
          invoke: {
            src: "add",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        CLEAR_ALL_ASSETS: {
          invoke: {
            src: "clearAllAssets",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        REMOVE: {
          invoke: {
            src: "remove",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        SELECT_ASSET: {
          invoke: {
            src: "select",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        CLOSE: {
          invoke: {
            src: "close",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        LOAD_ASSETS: {
          invoke: {
            src: "loadAssets",
            onDone: {
              target: "OPEN",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        OPEN: {
          invoke: {
            src: "open",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        ERROR: {
          entry: () => {
            console.log("Error");
          },
        },
      },
    },
    {
      services: {
        selectComponent: async ({ service }, { component }: { component: ComponentDefinition }) => {
          service.selectComponent(component);
          return Promise.resolve();
        },

        deselectComponent: async ({ service }) => {
          service.deselectComponent();
          return Promise.resolve();
        },

        init: async ({ service }) => {
          await service.loadAssets();

          return Promise.resolve();
        },

        add: async ({ service }, event: { asset: Asset }) => {
          service.add(event.asset);
          return Promise.resolve();
        },

        remove: async ({ service }, event: { asset: Asset }) => {
          service.remove(event.asset);
          return Promise.resolve();
        },

        select: async ({ service }, { asset }: { asset: Asset }) => {
          service.select(asset, false);
          return Promise.resolve();
        },

        close: async ({ service }) => {
          service.close();
          return Promise.resolve();
        },

        open: async ({ service }) => {
          service.open();
          return Promise.resolve();
        },

        loadAssets: async ({ service }) => {
          await service.loadAssets();
          return Promise.resolve();
        },

        clearAllAssets: async ({ service }) => {
          service.clearAllAssets();
          return Promise.resolve();
        },
      },
      actions: {
        notify: ({ service }) => {
          service.notifySubscribers();
        },
      },
      guards: {
        shouldOpen: ({ service }) => {
          return !service.state.open;
        },
      },
    }
  );
};
