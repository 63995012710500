import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { projectsService } from "../../../../../../../machines/projects/projects.instance.ts";
import { modalMachineInstance } from "../../index.ts";
import { assetManagerInstance } from "../../../asset-manager";

export const MyDropzone = () => {
  const onDrop = useCallback(async (acceptedFiles) => {
    // Do something with the files
    const formData = new FormData();
    acceptedFiles.forEach((file) => {
      formData.append("files", file);
    });
    const activeProject = projectsService.getActiveProject();
    const projectId = activeProject?.projectId;

    if (!projectId) return;

    //TODO move to state machine and service
    await axios.post(`/api/project-assets/upload/${projectId.replace("/", "%2F")}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    assetManagerInstance.send("CLOSE_MODAL");
    modalMachineInstance.send("CLOSE");
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/svg": [".svg"],
      "image/jpg": [".jpg"],
    },
    maxFiles: 5,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? <p>Drop the files here ...</p> : <p>Drag 'n' drop some files here, or click to select files</p>}
    </div>
  );
};
