import { useEffect } from "react";
import { editorService } from "../editor";

import { TLayerManager } from "./LayerManager.types";

import "./LayerManager.css";

export const LayerManager: TLayerManager = () => {
  const editor = editorService?.getEditor();

  useEffect(() => {
    if (editor) {
      // response for subsequent renders
      editor.onReady(() => {
        const layerManagerContainer = document.getElementById("gjs-layer-manager");

        if (layerManagerContainer) {
          layerManagerContainer.appendChild(editor.LayerManager.render());
        }
      });
    }
  }, []);

  return (
    <div className="layer-manager">
      <div className="layer-manager__header">
        <h2 className="layer-manager__label">Layers</h2>
      </div>
      <div className="layer-manager__content">
        <div id={"gjs-layer-manager"}></div>
      </div>
    </div>
  );
};
