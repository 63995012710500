import { BlocksMenu } from "../../../pages/Alpha_O/modules/blocks/BlocksMenu";
import { useEffect, useState } from "react";
import { EditorServiceState } from "../../../pages/Alpha_O/modules/editor/editor.service";
import { editorInstance, editorService } from "../../../pages/Alpha_O/modules/editor";
import { assetManagerInstance, assetManagerService } from "../../../pages/Alpha_O/modules/asset-manager";
import { AssetManagerState } from "../../../pages/Alpha_O/modules/asset-manager/asset-manager.service";
import { AssetManagerMenu } from "../../../pages/Alpha_O/modules/asset-manager/AssetManagerMenu";
import { LayerManager } from "../../../pages/Alpha_O/modules/layer-manager/LayerManager";
import { ComingSoon } from "../../../atoms/CoomingSoon";
import { PagesMenu } from "../../../pages/Alpha_O/modules/pages/PagesMenu";
import { DesignStyleMenu } from "../DesignStyleManu/DesignStyleMenu";
import { LoaderBackdrop } from "../../../molecules/LoaderBackdrop";
import { Sections } from "../../../../assets/svg/Sections";
import { Assets } from "../../../../assets/svg/Assets";
import { AddComponent } from "../../../../assets/svg/AddComponent";
import { Pages } from "../../../../assets/svg/Pages";
import { DesignStyle } from "../../../../assets/svg/DesignStyle";

import { Panels, TLeftSidebar } from "./LeftSidebar.types";

import "./LeftSidebar.css";

export const LeftSidebar: TLeftSidebar = () => {
  const [editoState, updateEditorState] = useState<EditorServiceState>(editorService.state);
  const [_, updateAssetManagerState] = useState<AssetManagerState>(assetManagerService.state);
  const [activePanel, setActivePanel] = useState<Panels>(null);

  useEffect(() => {
    assetManagerService.subscribe((state) => updateAssetManagerState(state));
    editorService.subscribe((state) => updateEditorState(state));

    return () => {
      assetManagerService.unsubscribe(updateAssetManagerState);
      editorService.unsubscribe(updateEditorState);
      setActivePanel(null);
    };
  }, []);

  // selection img cause open/close asset manager
  useEffect(() => {
    if (assetManagerService.state.open) {
      setActivePanel("MyAssets");
    }

    if (!assetManagerService.state.open && activePanel === "MyAssets") {
      setActivePanel(null);
    }
  }, [assetManagerService.state.open]);

  // editor machine can open/lose Components tab
  useEffect(() => {
    if (editorService.addNewElementPanelVisible) {
      setActivePanel("Components");
    }

    if (!editorService.addNewElementPanelVisible && activePanel === "Components") {
      setActivePanel(null);
    }
  }, [editorService.addNewElementPanelVisible]);

  const handleAssetManagerTabClick = () => {
    if (activePanel === "MyAssets") {
      assetManagerInstance.send("CLOSE");
      setActivePanel(null);
    } else {
      assetManagerInstance.send("OPEN");
      setActivePanel("MyAssets");
    }
  };

  const handleDesignStyleTabClick = () => {
    if (activePanel === "DesignStyle") {
      setActivePanel(null);
    } else {
      setActivePanel("DesignStyle");
    }
  };

  const handleLayerManagerTabClick = () => {
    if (activePanel === "Layers") {
      setActivePanel(null);
    } else {
      setActivePanel("Layers");
    }
  };

  const handleComponentsTabClick = () => {
    if (activePanel === "Components") {
      setActivePanel(null);
      editorInstance.send("HIDE_ADD_NEW_ELEMENT_PANEL");
    } else {
      setActivePanel("Components");
      editorInstance.send("SHOW_ADD_NEW_ELEMENT_PANEL");
    }
  };

  const handlePagesTabClick = () => {
    if (activePanel === "Pages") {
      setActivePanel(null);
    } else {
      setActivePanel("Pages");
    }
  };

  const handleClosePanel = () => {
    setActivePanel(null);
  };

  return (
    <aside className="left-sidebar">
      <nav className="lef-sidebar__nav">
        <ul className="left-sidebar__list">
          <li className={`left-sidebar__list-el${activePanel === "Pages" ? " active" : ""}`}>
            <button className="left-sidebar__tab" onClick={handlePagesTabClick}>
              <Pages className="left-sidebar__pages-svg" />
            </button>
            <span className="left-sidebar__hint">Pages</span>
          </li>
          <li className={`left-sidebar__list-el${activePanel === "Components" ? " active" : ""}`}>
            <button className="left-sidebar__tab" onClick={handleComponentsTabClick}>
              <AddComponent className="left-sidebar__components-svg" />
            </button>
            <span className="left-sidebar__hint">Components</span>
          </li>
          <li className="left-sidebar__list-el">
            <ComingSoon>
              <button className="left-sidebar__tab">
                <Sections />
              </button>
            </ComingSoon>
          </li>
          <li className={`left-sidebar__list-el${activePanel === "MyAssets" ? " active" : ""}`}>
            <button className="left-sidebar__tab" onClick={handleAssetManagerTabClick}>
              <Assets className="left-sidebar__assets-svg" />
            </button>
            <span className="left-sidebar__hint">Assets</span>
          </li>
          <li className={`left-sidebar__list-el${activePanel === "DesignStyle" ? " active" : ""}`}>
            <button className="left-sidebar__tab" onClick={handleDesignStyleTabClick}>
              <DesignStyle className="left-sidebar__design-style-svg" />
            </button>
            <span className="left-sidebar__hint">Theme</span>
          </li>
        </ul>
      </nav>
      <div className="panels" style={{ zIndex: activePanel === null ? -1 : 10 }}>
        <div className={`panels__blocks-menu${activePanel === "Components" ? " active" : ""}`}>
          {editorService.addNewElementPanelVisible && <BlocksMenu />}
        </div>
        <div className={`panels__asset-manager${activePanel === "MyAssets" ? " active" : ""}`}>
          {assetManagerService.state.open ? <AssetManagerMenu /> : <LoaderBackdrop />}
        </div>
        <div className={`panels__pages-menu${activePanel === "Pages" ? " active" : ""}`}>
          {activePanel === "Pages" ? <PagesMenu closePanel={handleClosePanel} /> : null}
          {activePanel === "Pages" ? <LayerManager /> : null}
        </div>
        <div className={`panels__design-style${activePanel === "DesignStyle" ? " active" : ""}`}>
          {activePanel === "DesignStyle" ? <DesignStyleMenu closePanel={handleClosePanel} /> : null}
        </div>
      </div>
    </aside>
  );
};
