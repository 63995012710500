import { useEffect } from "react";
import { AlertTriangle } from "../../../../../../../assets/svg/alert-triangle";

import { TModal } from "./Modal.types";

import "./Modal.css";

export const Modal: TModal = ({ onClick, onClose, title, description, cancelButtonLabel, confirmButtonLabel }) => {
  const handleKeyPropagation = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      event.stopPropagation();
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
      if (event.key === "Enter") {
        onClick();
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div
      className="project-card-modal__backdrop"
      role="menu"
      tabIndex={-1}
      onClick={(e) => e.stopPropagation()}
      onKeyDown={handleKeyPropagation}
    >
      <div className="project-card-modal__content">
        <div className="project-card-modal__header">
          <div className="project-card-modal__icon">
            <AlertTriangle />
          </div>
          <h4 className="project-card-modal__title">{title}</h4>
          <span className="project-card-modal__message">{description}</span>
        </div>
        <div className="project-card-modal__footer">
          <button
            className="project-card-modal__button project-card-modal__button--cancel"
            type="button"
            onClick={onClose}
          >
            {cancelButtonLabel}
          </button>
          <button
            className="project-card-modal__button project-card-modal__button--delete"
            type="button"
            onClick={() => {
              onClick();
              onClose();
            }}
          >
            {confirmButtonLabel}
          </button>
        </div>
      </div>
    </div>
  );
};
