import { type CSSProperties } from "react";

type TPage = {
  className?: string;
  style?: CSSProperties;
};

export const Page = ({ className, style }: TPage) => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_387_26603"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="20"
      height="21"
    >
      <rect y="0.5" width="20" height="20" fill="#D9D9D9" className={className} style={style} />
    </mask>
    <g mask="url(#mask0_387_26603)">
      <path
        d="M5.25646 18.4163C4.83549 18.4163 4.47917 18.2705 4.1875 17.9788C3.89583 17.6872 3.75 17.3309 3.75 16.9099V4.08947C3.75 3.66849 3.89583 3.31217 4.1875 3.02051C4.47917 2.72884 4.83549 2.58301 5.25646 2.58301H11.2517C11.4525 2.58301 11.6456 2.62204 11.831 2.70009C12.0163 2.77801 12.1774 2.88537 12.3142 3.02217L15.8108 6.51884C15.9476 6.65565 16.055 6.81669 16.1329 7.00197C16.211 7.18738 16.25 7.38051 16.25 7.58134V16.9099C16.25 17.3309 16.1042 17.6872 15.8125 17.9788C15.5208 18.2705 15.1645 18.4163 14.7435 18.4163H5.25646ZM11.25 6.82967V3.83301H5.25646C5.19229 3.83301 5.13354 3.85974 5.08021 3.91322C5.02674 3.96655 5 4.0253 5 4.08947V16.9099C5 16.9741 5.02674 17.0328 5.08021 17.0861C5.13354 17.1396 5.19229 17.1663 5.25646 17.1663H14.7435C14.8077 17.1663 14.8665 17.1396 14.9198 17.0861C14.9733 17.0328 15 16.9741 15 16.9099V7.58301H12.0033C11.7885 7.58301 11.6092 7.51113 11.4656 7.36738C11.3219 7.22377 11.25 7.04454 11.25 6.82967Z"
        fill="#71717A"
      />
    </g>
  </svg>
);
