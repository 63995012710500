import { type CSSProperties } from "react";

type TSizeTablet = {
  className?: string;
  style?: CSSProperties;
};

export const SizeTablet = ({ className, style }: TSizeTablet) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M9.66663 14.8529H11.3333M6.12496 2.5H14.875C15.6804 2.5 16.3333 3.17157 16.3333 4V16C16.3333 16.8284 15.6804 17.5 14.875 17.5H6.12496C5.31954 17.5 4.66663 16.8284 4.66663 16V4C4.66663 3.17157 5.31954 2.5 6.12496 2.5Z"
      stroke="#71717A"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
